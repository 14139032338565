import { Box } from "@mui/material";
import errorSvg from "@pd/assets/svgs/stitch-error.svg";

type Props = {
  style?: object;
  size?: "small" | "medium" | "large";
};
export default function StitchErrorIcon({
  style = {},
  size = "medium",
}: Props) {
  const small = (
    <Box sx={{ ...style }}>
      <img src={errorSvg} alt="Error" style={{ width: "50px" }} />
    </Box>
  );
  if (size === "small") {
    return small;
  }

  const medium = (
    <Box sx={{ ...style }}>
      <img src={errorSvg} alt="Error" style={{ width: "120px" }} />
    </Box>
  );
  if (size === "medium") {
    return medium;
  }

  return (
    <Box sx={{ ...style }}>
      <img src={errorSvg} alt="Error" />
    </Box>
  );
}
