import { useCallback, useEffect, useState } from "react";
import {
  usePlaidLink,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import { isMerchantDash } from "@pd/utils/appCheck";
import pla from "../redux/actions";
import { selectLinkToken } from "../redux/selectors";

export default function usePlaid() {
  const dispatch = useAppDispatch();
  const linkToken = useAppSelector(selectLinkToken);
  const [shouldOpen, setShouldOpen] = useState(false);

  useEffect(() => {
    dispatch(pla.tokens.apiError({ message: "", status: 0 }));
    dispatch(pla.accounts.apiError({ message: "", status: 0 }));
    return () => {
      dispatch(pla.tokens.clearTokens());
    };
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    // NOTE: https://plaid.com/docs/link/web/#onsuccess
    (onSuccessToken, metaData) => {
      setShouldOpen(false);
      const accountIds = metaData.accounts
        .filter((acct) => acct.subtype === "checking")
        .map((n) => n.id);
      if (accountIds.length && isMerchantDash()) {
        dispatch(pla.tokens.linkBankAccounts(onSuccessToken, accountIds));
      }
      dispatch(pla.events.setPlaidLinkSuccess(onSuccessToken, metaData));
    },
    [],
  );
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metaData) => {
    // NOTE: https://plaid.com/docs/link/web/#onevent
    dispatch(pla.events.setPlaidLinkEvent(eventName, metaData));
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>((onExitError, metaData) => {
    // NOTE: https://plaid.com/docs/link/web/#onexit
    dispatch(pla.tokens.apiFetching(false));
    dispatch(pla.tokens.clearTokens());
    setShouldOpen(false);
    if (onExitError) {
      dispatch(pla.accounts.setAcctUpdateOnError(onExitError, metaData));
    }
    dispatch(pla.events.setPlaidLinkExit(onExitError, metaData));
  }, []);

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  const openPlaidLinkModal = useCallback(
    ({ retry, accountId }: { retry: boolean; accountId: string }) => {
      if (retry) {
        if (!accountId) {
          console.error(
            "No account ID provided to `startPlaidLink` when retrying.",
          );
        } else {
          dispatch(pla.accounts.resetAcctError(accountId));
        }
      }
      if (ready) {
        setTimeout(() => open(), 500);
      }
    },
    [open, ready],
  );

  useEffect(() => {
    if (linkToken && ready) {
      openPlaidLinkModal({ retry: false, accountId: "" });
    }
  }, [shouldOpen, linkToken, ready]);

  const startPlaidLink = useCallback(() => {
    setShouldOpen(true);
    dispatch(pla.tokens.getLinkToken());
  }, []);

  return {
    startPlaidLink,
    plaidIsReady: ready,
  };
}
