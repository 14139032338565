import { createReducer } from "@reduxjs/toolkit";
import { createAsyncSlice } from "@pd/redux/types";
import { CreditApplicationStateType } from "@pd/layouts/BuyerCredit/types";
import ca from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";

const initAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
};

const initBusinessInfoState = createAsyncSlice({
  businessName: "",
  ein: "",
  website: "",
  address: { ...initAddress },
  isBankLinked: false,
  status: "",
});

const businessInfoReducer = createReducer<CreditApplicationStateType>(
  initBusinessInfoState,
  (builder) => {
    builder
      .addCase(ca.apiFetching, onApiFetching)
      .addCase(ca.apiError, onApiError)
      .addCase(ca.apiSuccess, onApiSuccess)
      .addCase(ca.clearAsyncState, onClearAsyncState)
      .addCase(ca.setBusinessName, onSetBusinessName)
      .addCase(ca.clearBusinessName, onClearBusinessName)
      .addCase(ca.setEin, onSetEin)
      .addCase(ca.clearEin, onClearEin)
      .addCase(ca.setWebsite, onSetWebsite)
      .addCase(ca.clearWebsite, onClearWebsite)
      .addCase(ca.setAddress, onSetAddress)
      .addCase(ca.clearAddress, onClearAddress)
      .addCase(ca.setIsBankLinked, onSetIsBankLinked)
      .addCase(ca.hydrateCreditApplication, onHydrateCreditApplication);
  },
);

export function onApiFetching(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onClearAsyncState(state: CreditApplicationStateType) {
  state.fetching = false;
  state.success = false;
  state.error = { message: "", status: 0 };
}

export function onSetBusinessName(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.setBusinessName>,
) {
  state.data.businessName = action.payload.value;
}

export function onClearBusinessName(state: CreditApplicationStateType) {
  state.data.businessName = "";
}

export function onSetEin(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.setEin>,
) {
  state.data.ein = action.payload.value;
}

export function onClearEin(state: CreditApplicationStateType) {
  state.data.ein = "";
}

export function onSetWebsite(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.setWebsite>,
) {
  state.data.website = action.payload.value;
}

export function onClearWebsite(state: CreditApplicationStateType) {
  state.data.website = "";
}

export function onSetAddress(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.setAddress>,
) {
  state.data.address = action.payload.value;
}

export function onClearAddress(state: CreditApplicationStateType) {
  state.data.address = initAddress;
}

export function onSetIsBankLinked(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.setIsBankLinked>,
) {
  state.data.isBankLinked = action.payload.value;
}

export function onHydrateCreditApplication(
  state: CreditApplicationStateType,
  action: ReturnType<typeof ca.hydrateCreditApplication>,
) {
  state.data.businessName = action.payload.value.data.companyName;
  state.data.ein = action.payload.value.data.taxId;
  state.data.website = action.payload.value.data.website;
  state.data.address = {
    ...action.payload.value.data.address,
    country: "USA",
  };
  state.data.status = action.payload.value.data.status;
}

export default businessInfoReducer;
