import {
  Typography,
  InputLabel,
  OutlinedInput,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { defaultAddress, AddressDbType } from "@pd/redux/types/dbTypes";
import {
  generateNotEmptyValidator,
  validatePostalCode,
} from "@pd/utils/validation";
import { useState } from "react";
import USStateSelect from "@pd/components/USStateSelect";

export type AddressValidationErrors = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
};

type Props = {
  address: AddressDbType | null;
  disabled?: boolean;
  required?: boolean;
  showLine2?: boolean;
  onAddressChange: (value: AddressDbType) => void;
  onValidationChange: (errors: AddressValidationErrors) => void;
};

export default function AddressInputSection({
  disabled = false,
  required = false,
  showLine2 = true,
  ...props
}: Props) {
  const theme = useTheme();
  const [validationErrors, setValidationErrors] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const hasValidationErrors = (): boolean =>
    [
      validationErrors.line1,
      validationErrors.city,
      validationErrors.state,
      validationErrors.postalCode,
    ].some(Boolean);

  // NOTE: This type is weird, but all it does is map a string to a validation function.
  // The validation function can have a callback "cb" with an error string in it.
  const addressValidators: {
    [key: string]: (value: string, cb: (error: string) => void) => void;
  } = {
    line1: generateNotEmptyValidator("Street 1 address is required"),
    city: generateNotEmptyValidator("City is required"),
    state: generateNotEmptyValidator("State is required"),
    postalCode: validatePostalCode,
  };

  const errorMessages = Object.values(validationErrors).filter(
    (oneError: string) => oneError,
  );

  const handleOnChange = (keyName: string, value: string) => {
    const newAddress: AddressDbType = {
      ...defaultAddress,
      ...props.address,
      [keyName]: value,
      country: "USA",
    };
    props.onAddressChange(newAddress);
  };

  const handleOnBlur = (keyName: string, lastVal: string) => {
    if (!required && !lastVal) {
      return;
    }
    const fieldValidator = addressValidators[keyName];
    if (!fieldValidator) {
      return;
    }
    fieldValidator(lastVal, (error: string) => {
      const newValidationErrors = { ...validationErrors, [keyName]: error };
      setValidationErrors(newValidationErrors);
      props.onValidationChange(newValidationErrors);
    });
  };

  const handleOnUsStateCodeChange = (value: string) => {
    handleOnChange("state", value);
    handleOnBlur("state", value);
  };

  return (
    <Grid container>
      <InputLabel sx={{ pb: 1 }}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Address</Typography>
          {required && (
            <Typography variant="subtitle2" sx={{ pt: "6px" }}>
              *
            </Typography>
          )}
        </Stack>
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("line1", e.target.value)}
            onBlur={(e) => handleOnBlur("line1", e.target.value)}
            value={props.address?.line1 || ""}
            placeholder="Street 1"
            sx={{
              width: "100%",
              "&.Mui-focused": {
                boxShadow: "none",
                outline: "none",
              },
            }}
            style={{
              border: validationErrors.line1
                ? `1px solid ${theme.palette.error.main}`
                : "",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("line2", e.target.value)}
            onBlur={(e) => handleOnBlur("line2", e.target.value)}
            value={props.address?.line2 || ""}
            placeholder="Street 2"
            sx={{
              display: showLine2 ? "block" : "none",
              width: "100%",
            }}
            style={{
              border: validationErrors.line2
                ? `1px solid ${theme.palette.error.main}`
                : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("city", e.target.value)}
            onBlur={(e) => handleOnBlur("city", e.target.value)}
            value={props.address?.city || ""}
            placeholder="City"
            sx={{
              width: "100%",
              "&.Mui-focused": {
                boxShadow: "none",
                outline: "none",
              },
            }}
            style={{
              border: validationErrors.city
                ? `1px solid ${theme.palette.error.main}`
                : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <USStateSelect
            onChange={(e) => {
              handleOnUsStateCodeChange(e.target.value);
            }}
            initialStateCode={props.address?.state}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OutlinedInput
            disabled={disabled}
            onChange={(e) => handleOnChange("postalCode", e.target.value)}
            onBlur={(e) => handleOnBlur("postalCode", e.target.value)}
            value={props.address?.postalCode || ""}
            placeholder="Postal code"
            sx={{
              width: "100%",
              "&.Mui-focused": {
                boxShadow: "none",
                outline: "none",
              },
            }}
            style={{
              border: validationErrors.postalCode
                ? `1px solid ${theme.palette.error.main}`
                : "",
            }}
          />
        </Grid>
      </Grid>
      {hasValidationErrors() && (
        <Stack
          gap={2}
          sx={{
            pt: "16px",
            pl: "10px",
          }}
        >
          {errorMessages.map((error: string) => (
            <Typography
              key={error}
              variant="subtitle1"
              color="error"
              sx={{
                lineHeight: "16px",
              }}
            >
              {error}
            </Typography>
          ))}
        </Stack>
      )}
    </Grid>
  );
}
