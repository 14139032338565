import { createReducer } from "@reduxjs/toolkit";
import { PlaidEventsStateType } from "../../types";
import pla from "../actions";

export const initialState: PlaidEventsStateType = {
  success: {
    publicToken: "",
    metadata: {
      institution: null,
      accounts: [],
      link_session_id: "",
    },
  },
  exit: {
    error: {
      error_type: "",
      error_code: "",
      error_message: "",
      display_message: "",
    },
    metadata: {
      institution: null,
      status: null,
      link_session_id: "",
      request_id: "",
    },
  },
  event: {
    eventName: "",
    metadata: {
      error_type: null,
      error_code: null,
      error_message: null,
      exit_status: null,
      institution_id: null,
      institution_name: null,
      institution_search_query: null,
      mfa_type: null,
      view_name: null,
      selection: null,
      timestamp: "",
      link_session_id: "",
      request_id: "",
    },
  },
};

export default function plaidEventsReducer() {
  return createReducer<PlaidEventsStateType>(initialState, (builder) => {
    builder
      .addCase(pla.events.setPlaidLinkSuccess, onSetPlaidLinkSuccess)
      .addCase(pla.events.clearPlaidLinkSuccess, onClearPlaidLinkSuccess)
      .addCase(pla.events.setPlaidLinkExit, onSetPlaidLinkExit)
      .addCase(pla.events.clearPlaidLinkExit, onClearPlaidLinkExit)
      .addCase(pla.events.setPlaidLinkEvent, onSetPlaidLinkEvent)
      .addCase(pla.events.clearPlaidLinkEvent, onClearPlaidLinkEvent);
  });
}

export function onSetPlaidLinkSuccess(
  state: PlaidEventsStateType,
  action: ReturnType<typeof pla.events.setPlaidLinkSuccess>,
) {
  state.success = { ...action.payload };
}

export function onClearPlaidLinkSuccess(state: PlaidEventsStateType) {
  state.success = initialState.success;
}

export function onSetPlaidLinkExit(
  state: PlaidEventsStateType,
  action: ReturnType<typeof pla.events.setPlaidLinkExit>,
) {
  state.exit = { ...action.payload };
}

export function onClearPlaidLinkExit(state: PlaidEventsStateType) {
  state.exit = initialState.exit;
}

export function onSetPlaidLinkEvent(
  state: PlaidEventsStateType,
  action: ReturnType<typeof pla.events.setPlaidLinkEvent>,
) {
  state.event = { ...action.payload };
}

export function onClearPlaidLinkEvent(state: PlaidEventsStateType) {
  state.event = initialState.event;
}
