import { combineReducers } from "@reduxjs/toolkit";
import { PlaidLinkStateType } from "@pd/components/PlaidLink/types";
import createTokensReducer from "./tokens";
import createAccountsReducer from "./accounts";
import createEventsReducer from "./events";

const plaidReducer = combineReducers<PlaidLinkStateType>({
  tokens: createTokensReducer(),
  linkedAccounts: createAccountsReducer(),
  events: createEventsReducer(),
});
export default plaidReducer;
