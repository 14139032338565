import React, { useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router";
import { TableRow, TableCell, Box, Stack, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import DynamicTableHOC from "@pd/components/DynamicTable";
import formatPhone from "@pd/utils/formatPhone";
import { SortDirType, stableSort, getComparator } from "@pd/utils/stableSort";
import {
  selectAllVendors,
  selectAllVendorsFiltered,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/vendors/allVendors";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import StitchLoading from "@pd/components/StitchLoading";
import { FadeInBox } from "@pd/components/FadeComponents";
import ExpiredLabel from "@pd/components/ExpiredLabel";
import IneligibleLabel from "@pd/components/IneligibleLabel";
import StatusCell from "@pd/components/DynamicTable/StatusCell";
import CopyIdButton from "@pd/components/CopyIdButton";
import {
  selectHasTableFilters,
  selectSortDir,
  selectSortKey,
  selectSortEnabled,
  selectTableTotalRows,
  selectTableOffset,
  selectTableLimit,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import { TablePaginationType } from "@pd/layouts/MktplaceDashboard/types/tables";

import { isVendorCardIneligible } from "@pd/utils/cards";
import isCardExpired from "@pd/utils/isCardExpired";
import { vendorColumns } from "./tableSchemas";
import { VendorsTableDataType } from "../types";
import VendorsHeaderActions from "./VendorsHeaderActions";
import transformVendorTableData from "./utils/transformVendorTableData";

export default function VendorTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectTableFetching);
  // TODO: Render apiError w/retry button when able.
  // const apiError = useAppSelector(selectTableError);
  // const apiSuccess = useAppSelector(selectTableSuccess);
  const allVendors = useAppSelector(selectAllVendors);
  const hasFilters = useAppSelector(selectHasTableFilters);
  const filteredVendors = useAppSelector(selectAllVendorsFiltered);
  const totalRows = useAppSelector(selectTableTotalRows);
  const offset = useAppSelector(selectTableOffset);
  const limit = useAppSelector(selectTableLimit);
  const sortDir = useAppSelector(selectSortDir);
  const sortKey = useAppSelector(selectSortKey);
  const sortEnabled = useAppSelector(selectSortEnabled);

  useEffect(() => {
    dispatch(
      da.table.getTableData({
        dataSrc: "vendors",
      }),
    );
    return () => {
      dispatch(da.table.clearTableFilters());
    };
  }, []);

  useEffect(() => {
    const initialSortFilter: TablePaginationType = {
      total: totalRows,
      offset,
      limit,
      sortDir: sortDir === "desc" ? "desc" : "asc",
      sortKey,
      sortEnabled: false,
    };
    dispatch(
      da.table.getTableDataOnFilterChange({
        changeSrc: "filters",
        dataSrc: "vendors",
        filter: initialSortFilter,
      }),
    );
  }, [totalRows]);

  const handleRequestSort = useCallback(
    (_: any, newSortKey: string) => {
      if (!sortEnabled) {
        return;
      }
      const sortFilter: TablePaginationType = {
        total: totalRows,
        offset,
        limit,
        sortDir: sortDir === "asc" ? "desc" : "asc",
        sortKey: newSortKey,
        sortEnabled: false,
      };
      if (newSortKey !== sortKey) {
        sortFilter.sortDir = sortDir;
      }
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "vendors",
          filter: sortFilter,
        }),
      );
    },
    [sortEnabled, totalRows, offset, limit, sortDir, sortKey],
  );

  const rows = useMemo(
    () => transformVendorTableData(filteredVendors),
    [allVendors, hasFilters, filteredVendors],
  );

  const sortedRows = useMemo(
    () =>
      stableSort(
        rows,
        getComparator(
          sortDir as SortDirType,
          sortKey as keyof VendorsTableDataType,
        ),
      ),
    [rows, sortDir, sortKey],
  );

  const handleRowClick = (
    _: React.MouseEvent<unknown>,
    row: VendorsTableDataType,
  ) => {
    navigate(`/dashboard/vendors/${row.id}`, { replace: false });
  };

  const renderTable = () => {
    if (!apiFetching && !allVendors.length) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
            borderColor: "grey.300",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">No Vendors</Typography>
            <Typography variant="subtitle1" textAlign="center">
              Looks like you {"don't"} have any vendors. <br /> Add vendors via
              the API or via your onboarding process to get started.
            </Typography>
          </Stack>
        </Box>
      );
    }
    if (apiFetching && !allVendors.length) {
      return (
        <FadeInBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <StitchLoading size="large" />
        </FadeInBox>
      );
    }

    return (
      <DynamicTableHOC
        columnDefs={vendorColumns}
        onHeaderSort={handleRequestSort}
        order={sortDir}
        orderBy={sortKey as keyof VendorsTableDataType}
        tableSrc="vendors"
        showLoading={apiFetching}
        autoHidePagination={!sortedRows.length}
      >
        {sortedRows.map((row) => (
          <TableRow
            onClick={(event) => handleRowClick(event, row)}
            key={row.id}
            sx={{
              td: {
                fontFamily: "CircularRegular",
                fontSize: "14px",
                width: 150,
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <TableCell style={{ width: "20%" }}>{row.vendor}</TableCell>
            <TableCell style={{ maxWidth: "145px" }}>
              <CopyIdButton id={row.id} />
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              {formatPhone(row.phone)}
            </TableCell>
            <TableCell style={{ width: "15%" }}>{row.email}</TableCell>
            <TableCell style={{ maxWidth: "150px" }}>
              <CopyIdButton id={row.referenceId} />
            </TableCell>
            <TableCell style={{ maxWidth: "150px" }}>
              <Stack gap={1} direction="row">
                <Stack gap={1} sx={{ display: row.hasCard ? "flex" : "none" }}>
                  <IneligibleLabel show={isVendorCardIneligible(row)} />
                  <ExpiredLabel
                    show={
                      !isVendorCardIneligible(row) &&
                      isCardExpired(row.cardExpiration)
                    }
                  />
                </Stack>
                <Stack gap={1} sx={{ display: !row.hasCard ? "flex" : "none" }}>
                  <IneligibleLabel show={row.bankAccountDisabled} />
                </Stack>
                {row.account}
              </Stack>
            </TableCell>
            <TableCell style={{ width: "10%" }}>
              <StatusCell status={row.kycStatus} type="vendors" />
            </TableCell>
          </TableRow>
        ))}
      </DynamicTableHOC>
    );
  };

  return (
    <Stack>
      <VendorsHeaderActions />
      {renderTable()}
    </Stack>
  );
}
