import { createAction } from "@reduxjs/toolkit";

const routeActions = {
  redirect: createAction("buyerCredit/routes/setRoute", (route: string) => ({
    payload: { route },
  })),
  clearNextRoute: createAction("buyerCredit/routes/clearRoute"),
};

export default routeActions;
