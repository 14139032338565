import { CloseCircleOutlined } from "@ant-design/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

type Props = {
  loading: boolean;
  apiError: ErrorPayload;
};
export default function StatusMessage(props: Props) {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="flex-start"
      sx={{
        mt: "25px",
        minHeight: "24px",
        display: props.apiError.message && !props.loading ? "block" : "none",
      }}
      gap={3}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: props.apiError.message && !props.loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <CloseCircleOutlined
          style={{ color: theme.palette.error.main, fontSize: "20px" }}
        />
        <Typography variant="h5" sx={{ color: theme.palette.error.main }}>
          {props.apiError.message ||
            "There was an issue with your buyer details. Please try again."}
        </Typography>
      </Stack>
    </Stack>
  );
}
