import { createReducer } from "@reduxjs/toolkit";
import { isAsyncStateType, createAsyncSlice } from "@pd/redux/types";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import portalActions from "../actions";
import type { BuyerPortalStateType } from "../types";

export const initialState = {
  // auth: createAsyncSlice({ loggedIn: false, linkExpired: false }),
  // invoices: createAsyncSlice([]),
  // targetInvoice: createAsyncSlice({}),
  // payment: createAsyncSlice({}),
  merchantInfo: createAsyncSlice({ logoSlug: "" }),
  buyerIdentity: createAsyncSlice({ id: "", name: "", email: "" }),
  linkedAccounts: createAsyncSlice([]),
  autopayInfo: createAsyncSlice(null),
  termsInfo: createAsyncSlice(null),
  achWireInfo: createAsyncSlice(null),
};

const buyerPortalReducer = createReducer<BuyerPortalStateType>(
  initialState,
  (builder) => {
    builder.addCase(portalActions.fetching, onFetching);
    builder.addCase(portalActions.apiError, onApiError);
  },
);
export default buyerPortalReducer;

function onFetching(
  state: BuyerPortalStateType,
  action: ReturnType<typeof portalActions.fetching>,
) {
  const stateProperty = state[action.payload.stateKey];
  if (isAsyncStateType(stateProperty)) {
    stateProperty.fetching = action.payload.fetching;
  }
}

function onApiError(
  state: BuyerPortalStateType,
  action: ReturnType<typeof portalActions.apiError>,
) {
  const stateProperty = state[action.payload.stateKey];
  if (isAsyncStateType(stateProperty)) {
    stateProperty.fetching = false;
    if ("error" in stateProperty) {
      stateProperty.error = {
        status: MANUAL_ERROR_CODE,
        message: action.payload.error,
      };
    }
  }
}
