import pingPongActions from "./pingPong";
import allBuyerActions from "./buyers/allBuyers";
import crudBuyerActions from "./buyers/crudBuyers";
import creditActions from "./credit";
import allOrdersActions from "./orders/allOrders";
import crudOrderActions from "./orders/crudOrder";
import csvUploadOrdersActions from "./orders/csvUpload";
import tableActions from "./tables/tables";
import transferActions from "./transfers/transfers";
import allVendorActions from "./vendors/allVendors";
import crudVendorActions from "./vendors/crudVendor";
import settingsActions from "./settings";
import routeActions from "./routes";
import allTeamMembersActions from "./teams/allTeamMembers";
import crudTeamMembersActions from "./teams/crudTeamMember";

const actions = {
  pingPong: { ...pingPongActions },
  buyers: {
    all: { ...allBuyerActions },
    crud: { ...crudBuyerActions },
  },
  credit: {
    app: { ...creditActions },
  },
  orders: {
    all: { ...allOrdersActions },
    crud: { ...crudOrderActions },
    csvUpload: { ...csvUploadOrdersActions },
  },
  transfers: { ...transferActions },
  vendors: {
    all: { ...allVendorActions },
    crud: { ...crudVendorActions },
  },
  teams: {
    all: { ...allTeamMembersActions },
    crud: { ...crudTeamMembersActions },
  },
  table: { ...tableActions },
  routes: { ...routeActions },
  settings: { ...settingsActions },
};

export default actions;
