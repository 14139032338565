import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { useState } from "react";

type Props = {
  keyName: string;
  value?: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (
    keyName: string,
    value: string,
    isValid: boolean,
    originalEvent?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  validator?: (
    value: string,
    errorMsgHandler: (errorMsg: string) => void,
  ) => boolean;
  maxChars?: number;
};

export default function StringInputSection({
  disabled = false,
  required = false,
  value = "",
  validator = undefined,
  maxChars = NaN,
  ...props
}: Props) {
  const [showError, setShowError] = useState(false);
  const [validationError, setValidationError] = useState("");
  const theme = useTheme();

  const handleOnChange = (
    newVal: string,
    originalEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isValid = !validator || validator(newVal, (_) => {});
    const truncated = Number.isNaN(maxChars)
      ? newVal
      : newVal.slice(0, maxChars);
    props.onChange(props.keyName, truncated, isValid, originalEvent);
  };

  const handleOnBlur = () => {
    if (!value) {
      if (required) {
        setValidationError("This field is required");
        setShowError(true);
      }
      return;
    }
    if (!validator) {
      setValidationError("");
      setShowError(false);
      return;
    }
    const valid = validator(value, (errorMsg) => {
      setValidationError(errorMsg);
    });
    setShowError(!valid);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
      }}
    >
      <InputLabel htmlFor={`outlinedInput-${props.keyName}`}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="body1">{props.label}</Typography>
          {required && (
            <Typography variant="subtitle2" sx={{ pt: "6px" }}>
              *
            </Typography>
          )}
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <OutlinedInput
          id={`outlinedInput-${props.keyName}`}
          name={`outlinedInput-${props.keyName}`}
          className="StringInputSection"
          disabled={disabled}
          onChange={(e) => handleOnChange(e.target.value, e)}
          onBlur={handleOnBlur}
          value={value}
          placeholder={props.placeholder}
          sx={{
            "&.Mui-focused": {
              boxShadow: "none",
              outline: "none",
            },
            border: showError ? `1px solid ${theme.palette.error.main}` : "",
          }}
        />
      </FormControl>
      <Typography
        variant="subtitle1"
        color="error"
        sx={{
          pt: "5px",
          pl: "10px",
          display: showError ? "block" : "none",
          lineHeight: "16px",
        }}
      >
        {validationError}
      </Typography>
    </Stack>
  );
}
