import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { FadeInStack } from "@pd/components/FadeComponents";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Stack, Typography } from "@mui/material";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import StringInputSection from "@pd/components/StringInputSection";
import {
  selectCreditAppFetching,
  selectCreditAppError,
  selectCreditAppSuccess,
  selectUiApp,
  selectApiApp,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/credit";
import {
  AddressDbType,
  BuyerDetailDbType,
  CreditAppStatusType,
} from "@pd/redux/types/dbTypes";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { validateWebsite } from "@pd/utils/validation";
import StitchLoading from "@pd/components/StitchLoading";
import StatusMessage from "@pd/components/ErrStatusMessage";
import AddressInputSection, {
  AddressValidationErrors,
} from "@pd/components/AddressInputSection";
import formatNamePosessive from "@pd/utils/formatNamePosessive";
import formatEin from "@pd/utils/formatEin";

type Props = {
  buyer: BuyerDetailDbType | null;
  onInviteCreated: (emailSent: boolean) => void;
};

export default function CreditInfoForm(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCreditAppFetching);
  const apiError = useAppSelector(selectCreditAppError);
  const apiSuccess = useAppSelector(selectCreditAppSuccess);
  const apiApp = useAppSelector(selectApiApp);
  const uiApp = useAppSelector(selectUiApp);

  const [onMounting, setOnMounting] = useState(true);
  const [uiFetching, setUiFetching] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [uiErrors, setUiErrors] = useState({
    companyName: false,
    taxId: false,
    website: false,
    address: false,
  });

  const hasUiErrors = () =>
    uiErrors.companyName ||
    uiErrors.website ||
    uiErrors.taxId ||
    uiErrors.address;

  useEffect(() => {
    if (apiError.status) {
      window.scrollTo(0, document.body.scrollHeight);
      setOnMounting(false);
      setUiFetching(false);
    } else if ((!apiFetching || apiError.status) && onMounting) {
      setOnMounting(false);
    } else if (!apiFetching && uiFetching) {
      setUiFetching(false);
    }
  }, [apiFetching, apiError, apiSuccess, saveSuccess, uiFetching]);

  const handleHydreateUiApp = () => {
    if (props.buyer) {
      dispatch(da.credit.app.hydrateUiApp(props.buyer));
    } else {
      dispatch(
        da.credit.app.apiError({
          message:
            "Missing buyer data to create credit application. Please refresh & try again.",
          status: MANUAL_ERROR_CODE,
        }),
      );
    }
  };

  const clearAsyncState = () => {
    dispatch(da.credit.app.apiSuccess(false));
    dispatch(da.credit.app.apiFetching(false));
    dispatch(da.credit.app.apiError({ message: "", status: 0 }));
  };

  useEffect(() => {
    clearAsyncState();
    dispatch(da.credit.app.clearState());
    setOnMounting(true);
    handleHydreateUiApp();
    return () => {
      clearAsyncState();
    };
  }, []);

  useEffect(() => {
    if (
      apiSuccess &&
      [
        apiApp?.status === CreditAppStatusType.invited,
        apiApp?.status === CreditAppStatusType.pending,
      ].some(Boolean)
    ) {
      props.onInviteCreated(emailSent);
      dispatch(da.credit.app.apiSuccess(false));
    }
  }, [apiSuccess, apiApp]);

  function handleOnChange(
    keyName: string,
    value: string | AddressDbType,
    isValid: boolean,
    originalEvent?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (
      keyName === "taxId" &&
      originalEvent &&
      "inputType" in originalEvent.nativeEvent &&
      originalEvent.nativeEvent.inputType === "deleteContentBackward"
    ) {
      const valueStr = value.toString();
      if (
        valueStr.length === 3 &&
        valueStr.charAt(valueStr.length - 1) === "-"
      ) {
        const nextValueSansDash = valueStr.substring(0, 2);
        dispatch(da.credit.app.setUiData(keyName, nextValueSansDash));
      }
      if (valueStr.length === 2) {
        dispatch(da.credit.app.setUiData(keyName, valueStr.substring(0, 1)));
      } else {
        dispatch(da.credit.app.setUiData(keyName, value));
      }
    } else {
      dispatch(da.credit.app.setUiData(keyName, value));
    }
    dispatch(da.credit.app.apiError({ message: "", status: 0 }));
    setUiErrors((prev) => ({ ...prev, [keyName]: !isValid }));
  }

  function shouldDisableSubmit() {
    return hasUiErrors() || !uiApp?.companyName;
  }

  function onSubmit({ sendEmail }: { sendEmail: boolean }) {
    if (shouldDisableSubmit()) {
      dispatch(
        da.credit.app.apiError({
          message: "Please fill out all fields",
          status: MANUAL_ERROR_CODE,
        }),
      );
      return;
    }
    if (uiApp == null) {
      return;
    }
    setSaveSuccess(false);
    setUiFetching(true);
    if (props.buyer && props.buyer.id && uiApp) {
      dispatch(
        da.credit.app.submit(
          {
            ...uiApp,
            status: sendEmail
              ? CreditAppStatusType.invited
              : CreditAppStatusType.pending,
          },
          sendEmail,
          props.buyer?.id || "",
        ),
      );
      setEmailSent(sendEmail);
    }
  }

  const onAddressValidationChange = (
    addressErrors: AddressValidationErrors,
  ) => {
    uiErrors.address = [
      addressErrors.line1,
      addressErrors.line2,
      addressErrors.city,
      addressErrors.state,
      addressErrors.postalCode,
    ].reduce((acc, curr) => Boolean(acc || curr), false);
    setUiErrors(uiErrors);
  };

  if (apiError.status) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <FadeInStack
      spacing={3}
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {onMounting ? (
        <Stack
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "50vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StitchLoading size="large" />
        </Stack>
      ) : (
        <>
          <Stack
            sx={{
              flexGrow: 1,
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              overflowY: "auto",
              overflowX: "auto",
              whiteSpace: "normal",
              "& .MuiAutocomplete-popper": {
                "::-webkit-scrollbar": {
                  width: "5px",
                  height: "7px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#grey",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#ddd",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  background: "#333",
                },
              },
            }}
            gap={2}
          >
            <Stack gap={4}>
              <Typography variant="h4">Review</Typography>
              <Typography variant="h4">
                Verify {formatNamePosessive(props.buyer?.companyName || "")}{" "}
                business standing with a credit review.
              </Typography>
              <Typography>{""}</Typography>
            </Stack>
            <StringInputSection
              keyName="companyName"
              value={uiApp?.companyName || ""}
              label="Company name"
              placeholder="Company Name"
              onChange={handleOnChange}
            />
            <StringInputSection
              keyName="taxId"
              value={formatEin(uiApp?.taxId || "")}
              maxChars={10}
              label="EIN"
              placeholder="12-3456789"
              onChange={handleOnChange}
            />
            <StringInputSection
              keyName="website"
              value={uiApp?.website || ""}
              label="Website"
              placeholder="http://www.example.com"
              onChange={handleOnChange}
              validator={validateWebsite}
            />
            <AddressInputSection
              address={uiApp?.address ? uiApp.address : null}
              showLine2={false}
              onAddressChange={(value) =>
                handleOnChange("address", value, true)
              }
              onValidationChange={onAddressValidationChange}
            />
          </Stack>
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            gap={1}
          >
            <StatusMessage loading={uiFetching} apiError={apiError} />
            <StitchAsyncButton
              buttonText={"Continue"}
              buttonWidth={"100%"}
              endAdornment={<ArrowRightOutlined style={{ fontSize: "24px" }} />}
              success={saveSuccess}
              loading={uiFetching}
              color={"primary"}
              variant={"contained"}
              logoColor={"white"}
              loadingSize={"small"}
              loadingPosition={{ top: -40, left: 0 }}
              onClick={() => onSubmit({ sendEmail: false })}
              style={{
                height: "56px",
                padding: "16px 24px",
                fontSize: "20px",
              }}
            />
            <StitchAsyncButton
              buttonText={`Request from ${
                props.buyer?.companyName || "Company"
              }`}
              buttonWidth={"100%"}
              endAdornment={<ArrowRightOutlined style={{ fontSize: "24px" }} />}
              success={false}
              loading={false}
              color={"inherit"}
              variant={"outlined"}
              logoColor={"black"}
              loadingSize={"small"}
              loadingPosition={{ top: -40, left: 0 }}
              onClick={() => onSubmit({ sendEmail: true })}
              style={{
                height: "56px",
                padding: "16px 24px",
                fontSize: "20px",
              }}
            />
          </Stack>
        </>
      )}
    </FadeInStack>
  );
}
