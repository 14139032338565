import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import GenericRetryError from "@pd/components/GenericRetryError";
import LocalErrorMsg from "@pd/components/LocalErrorMsg";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { getDedupedTimeZones, sortTimeZones } from "@pd/utils/timezones";
import { MuiTelInput } from "mui-tel-input";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateMuiTelInput,
  validateRole,
  validateTimezone,
} from "@pd/utils/validation";
import roleTypes from "./utils/roleTypes";
import { RoleSelectionType } from "../../../types";

const allTimezones = getDedupedTimeZones();

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  timezone: string;
  roleType: RoleSelectionType | null;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhone: (value: string) => void;
  setTimezone: (value: string) => void;
  setRoleType: (value: RoleSelectionType | null) => void;
  onConfirmCreate: () => void;
  onClose: () => void;
  apiFetching: boolean;
  apiSuccess: boolean;
  apiError: ErrorPayload;
};
export default function CreateTeamMemberForm(props: Props) {
  const theme = useTheme();
  const usPhoneRef = useRef<HTMLDivElement>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    role: "",
    timezone: "",
  });

  const hasErrors =
    uiErrors.firstName ||
    uiErrors.lastName ||
    uiErrors.phone ||
    uiErrors.email ||
    uiErrors.role ||
    uiErrors.timezone;

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const formattedPhoneNum = props.phone;
    const phoneIsValid = validateMuiTelInput(
      formattedPhoneNum,
      handleValidationResult("phone"),
    );
    const firstNameIsValid = validateFirstName(
      props.firstName,
      handleValidationResult("firstName"),
    );
    const lastNameIsValid = validateLastName(
      props.lastName,
      handleValidationResult("lastName"),
    );
    const emailIsValid = validateEmail(
      props.email,
      handleValidationResult("email"),
    );
    const roleIsValid = validateRole(
      props.roleType?.name || "",
      handleValidationResult("role"),
    );
    const timezoneValid = validateTimezone(
      props.timezone,
      handleValidationResult("timeZone"),
    );

    const invalidInput = [
      !firstNameIsValid,
      !lastNameIsValid,
      !phoneIsValid,
      !emailIsValid,
      !roleIsValid,
      !timezoneValid,
    ].some(Boolean);
    if (invalidInput) {
      setShowErrors(true);
      return;
    }

    if (!props.apiFetching) {
      props.onConfirmCreate();
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "firstName":
        return validateFirstName(value, handleValidationResult(name));
      case "lastName":
        return validateLastName(value, handleValidationResult(name));
      case "phone":
        return validateMuiTelInput(value, handleValidationResult(name));
      case "email":
        return validateEmail(value, handleValidationResult(name));
      case "role":
        return validateRole(value, handleValidationResult(name));
      case "timezone":
        return validateTimezone(value, handleValidationResult(name));
      default:
        return false;
    }
  };

  const handleOnPhoneChange = (value: string) => {
    validateMuiTelInput(value, handleValidationResult("phone"));
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      props.setPhone(nextValue);
    } else {
      props.setPhone(value);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    if (name === "firstName") {
      props.setFirstName(value);
    } else if (name === "lastName") {
      props.setLastName(value);
    } else if (name === "email") {
      props.setEmail(value);
    } else if (name === "role") {
      const targetRole = roleTypes.find((s) => s.name === value) || null;
      props.setRoleType(targetRole);
    } else if (name === "timezone") {
      props.setTimezone(value);
    }
  };

  const handleOnBlur = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    if (usPhoneRef.current) {
      usPhoneRef.current.style.outline = "none";
    }
    const { name, value } = e.target;
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        width: "75%",
        height: "100%",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="new-bank-account" gap={2} sx={{ minHeight: "400px" }}>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="First Name"
            name="firstName"
            value={props.firstName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, firstName: "" }))}
            className="firstName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.firstName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.firstName}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={props.lastName}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, lastName: "" }))}
            className="lastName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.lastName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.lastName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.lastName}
          </Typography>
        </Stack>
        <Stack>
          <input
            disabled={props.apiFetching}
            type="text"
            placeholder="Email"
            name="email"
            value={props.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onInput={() => setUiErrors((prev) => ({ ...prev, email: "" }))}
            className="email-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.email && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.email ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.email}
          </Typography>
        </Stack>
        <Stack>
          <MuiTelInput
            disableFormatting
            forceCallingCode
            defaultCountry="US"
            onChange={handleOnPhoneChange}
            onBlur={handleOnBlur}
            value={props.phone}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.phone ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.phone}
          </Typography>
        </Stack>
        <Stack>
          <Autocomplete
            id="role"
            disableClearable
            onChange={(_, value) => {
              handleOnChange({
                target: {
                  name: "role",
                  value: value?.name || "",
                },
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            onInputChange={(_, value, reason) => {
              if (reason === "reset") {
                const targetAcct = roleTypes.find(
                  (s) => s.name === value.toLowerCase(),
                );
                handleOnChange({
                  target: {
                    name: "role",
                    value: targetAcct?.name || "",
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
            // @ts-expect-error - ts(2322)
            value={props.roleType}
            options={roleTypes.sort((a, b) => -b.name.localeCompare(a.name))}
            isOptionEqualToValue={(option, value) =>
              option.name === value?.name
            }
            getOptionLabel={(option) =>
              `${option.name[0].toUpperCase()}${option.name.slice(1)}`
            }
            renderOption={(_props, option) => (
              <li key={option.name} {..._props} data-option-name={option.name}>
                {`${option.name[0].toUpperCase()}${option.name.slice(1)}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Role Type"
                style={{
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "CircularRegular",
                  width: "100%",
                }}
              />
            )}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.role ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.role}
          </Typography>
        </Stack>
        <Stack>
          <Autocomplete
            id="timezone"
            disableClearable
            onChange={(_, value) => {
              handleOnChange({
                target: {
                  name: "timezone",
                  value: value?.name || "",
                },
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            onInputChange={(_, value, reason) => {
              if (reason === "reset") {
                const targetTimezone = allTimezones.find(
                  (s) => s.name === value.toLowerCase(),
                );
                handleOnChange({
                  target: {
                    name: "timezone",
                    value: targetTimezone?.name || "",
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
            // @ts-expect-error - ts(2322)
            value={allTimezones.find((s) => s.name === props.timezone) || null}
            options={allTimezones.sort(sortTimeZones)}
            groupBy={(tz) => tz.continentName}
            isOptionEqualToValue={(option, value) =>
              option.name === value?.name
            }
            getOptionLabel={(option) => option.label}
            renderOption={(p, option) => (
              <li key={option.name} {...p} data-option-name={option.name}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Time zone"
                style={{
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "CircularRegular",
                  width: "100%",
                }}
              />
            )}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.timezone ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.timezone}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <GenericRetryError error={props.apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={props.apiError} />
        <StitchAsyncButton
          buttonText="Confirm"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={props.apiSuccess}
          loading={props.apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
          style={{
            height: "",
          }}
        />
        <Button variant="outlined" onClick={props.onClose}>
          Close
        </Button>
      </Stack>
    </FadeInStack>
  );
}
