import { all, call, put, takeLeading, takeLatest } from "redux-saga/effects";
import {
  DashboardBuyersCreateResType,
  DashboardBuyerByIdResType,
  DashboardBuyersCreateReqType,
  fetchCreateBuyer,
  fetchBuyerById,
} from "@pd/api/dashboard/buyers";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import tracer from "@pd/tracing";
import da from "../../actions";

export default function* crudBuyerSagas() {
  yield all([
    takeLeading(da.buyers.crud.createBuyer.toString(), onCreateBuyer),
    takeLatest(da.buyers.crud.getBuyerById.toString(), onGetBuyerById),
  ]);
}

function* onGetBuyerById(
  action: ReturnType<typeof da.buyers.crud.getBuyerById>,
) {
  try {
    yield all([
      put(da.buyers.crud.apiFetching(true)),
      put(da.buyers.crud.apiSuccess(false)),
      put(da.buyers.crud.apiError({ message: "", status: 0 })),
    ]);
    const res: DashboardBuyerByIdResType = yield call(
      fetchBuyerById,
      action.payload.id,
    );
    if ("error" in res) {
      tracer.warn(
        "Unable to fetch buyer by id",
        tracer.ids.domain.SAGAS.buyers,
        { error: res.error.message },
      );
      yield all([
        put(da.buyers.crud.apiError(res.error)),
        put(da.buyers.crud.apiSuccess(false)),
        put(da.buyers.crud.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(da.buyers.crud.setBuyerApiData(res.data)),
        put(da.buyers.crud.apiFetching(false)),
        put(da.buyers.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating the buyer. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.buyers.crud.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.buyers.crud.apiFetching(false)),
      put(da.buyers.crud.apiSuccess(false)),
    ]);
  }
}

function* onCreateBuyer(action: ReturnType<typeof da.buyers.crud.createBuyer>) {
  try {
    yield all([
      put(da.buyers.crud.apiFetching(true)),
      put(da.buyers.crud.apiSuccess(false)),
      put(da.buyers.crud.apiError({ message: "", status: 0 })),
    ]);
    const req: DashboardBuyersCreateReqType = {
      companyName: action.payload.buyer.companyName,
      referenceId: action.payload.buyer.extId,
      phone: action.payload.buyer.phone,
      email: action.payload.buyer.email,
      website: action.payload.buyer.website,
      address: action.payload.buyer.address,
    };
    const res: DashboardBuyersCreateResType = yield call(fetchCreateBuyer, req);
    if ("error" in res) {
      tracer.warn("Unable to create buyer", tracer.ids.domain.SAGAS.buyers, {
        error: res.error.message,
      });
      yield all([
        put(da.buyers.crud.apiError(res.error)),
        put(da.buyers.crud.apiSuccess(false)),
        put(da.buyers.crud.apiFetching(false)),
      ]);
    } else {
      tracer.info(
        "Buyer created successfully",
        tracer.ids.domain.SAGAS.buyers,
        { buyerId: res.data.id },
      );
      yield all([
        put(da.buyers.crud.setBuyerApiData(res.data)),
        put(da.buyers.crud.apiFetching(false)),
        put(da.buyers.crud.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating the buyer. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.buyers.crud.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.buyers.crud.apiFetching(false)),
      put(da.buyers.crud.apiSuccess(false)),
    ]);
  }
}
