import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AppStateType } from "@pd/redux/store/reducers";
import { MerchantProfileDbType } from "@pd/redux/types/dbTypes";
import { StartupStateType } from "@pd/redux/types/startup";
import { createSelector } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

export const selectAuthApiFetching = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): boolean => state.auth.fetching,
);

export const selectAuthApiError = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): ErrorPayload => state.auth.error,
);

export const selectAuthApiSuccess = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): boolean => state.auth.success,
);

export const selectCookieExpireTime = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): number => state.auth.data.cookieExpireTime,
);

export const selectShowRefreshSession = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): boolean => state.auth.data.showRefreshSession,
);

export const selectLoggedIn = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): boolean => state.auth.data.loggedIn,
);

export const selectJwt = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): string => state.auth.data.jwt,
);

export const selectIsExpired = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): boolean => {
    try {
      const decodedToken: { exp: string } = jwtDecode(state.auth.data.jwt);
      if (decodedToken == null) {
        return true;
      }
      const now = new Date();
      const expireDate = new Date(Number(decodedToken.exp) * 1000); // Convert from sec to ms
      const isExpired = expireDate < now;
      return isExpired;
    } catch (e) {
      return true;
    }
  },
);

export const selectProfile = createSelector(
  (state: AppStateType) => state.startup,
  (state: StartupStateType): MerchantProfileDbType | null =>
    state.auth.data.profile,
);
