import { createAction } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { MerchantProfileDbType } from "../types/dbTypes";

const actions = {
  apiFetching: createAction("auth/apiFetching", (fetching: boolean) => ({
    payload: { fetching },
  })),
  apiError: createAction("auth/apiError", (error: ErrorPayload) => ({
    payload: { error },
  })),
  apiSuccess: createAction("auth/apiSuccess", (success: boolean) => ({
    payload: { success },
  })),
  authorizeToken: createAction("auth/authorizeToken", (token: string) => ({
    payload: { token },
  })),

  // While this action does the same setting of the auth token as actions.authorizeToken,
  // this action is not included in the saga to check an auth token against the
  // backend. Instead it simply sets the authToken on the frontend.
  setAuthToken: createAction("auth/setAuthToken", (token: string) => ({
    payload: { token },
  })),

  sendEmailLink: createAction("auth/sendEmailLink", (email: string) => ({
    payload: { email },
  })),
  sendEmailLinkSuccess: createAction("auth/sendEmailLinkSuccess"),
  setLoggedIn: createAction("auth/setLoggedIn", (loggedIn: boolean) => ({
    payload: { loggedIn },
  })),
  setShowRefreshMsg: createAction(
    "auth/setShowRefreshMsg",
    (show: boolean) => ({
      payload: { show },
    }),
  ),
  refreshSession: createAction("auth/refreshSession"),
  setCookieUpdate: createAction(
    "auth/setCookieUpdate",
    (timestamp?: number) => ({ payload: { timestamp } }),
  ),
  resendPhoneCode: createAction("auth/resendPhoneCode"),
  submitPhoneCode: createAction(
    "auth/submitPhoneCode",
    (phoneCode: string) => ({ payload: { phoneCode } }),
  ),
  clearPhoneCode: createAction("auth/clearPhoneCode"),
  setOtpValid: createAction("auth/setOtpValid", (otpValid: boolean) => ({
    payload: { otpValid },
  })),
  logout: createAction("auth/logout"),
  setMerchantProfile: createAction(
    "auth/setMerchantProfile",
    (profile: MerchantProfileDbType) => ({
      payload: { profile },
    }),
  ),
};

export default actions;
