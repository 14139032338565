import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { Stack, Dialog, DialogContent } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import {
  selectApiBuyer,
  selectCrudBuyerError,
  selectCrudBuyerFetching,
  selectCrudBuyerSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/crudBuyer";
import { selectApiApp } from "@pd/layouts/MktplaceDashboard/redux/selectors/credit";
import StitchLoading from "@pd/components/StitchLoading";
import { CreditAppStatusType } from "@pd/redux/types/dbTypes";
import SubmissionInfo from "./components/SubmissionInfo";
import CreditInfoForm from "./components/CreditInfoForm";
import RequestInfoMsg from "./components/RequestInfoMsg";
import InviteSent from "./components/InviteSent";

type Props = {
  buyerId: string;
  onClose: () => void;
};
export default function CreditInvite(props: Props) {
  const dispatch = useAppDispatch();
  const [onMounting, setOnMounting] = useState(false);

  const apiFetching = useAppSelector(selectCrudBuyerFetching);
  const apiError = useAppSelector(selectCrudBuyerError);
  const apiSuccess = useAppSelector(selectCrudBuyerSuccess);
  const apiBuyer = useAppSelector(selectApiBuyer);
  const apiApp = useAppSelector(selectApiApp);

  const [showRequestInfoMsg, setShowRequestInfoMsg] = useState(false);
  const [showCreditForm, setShowCreditForm] = useState(false);
  const [showSubmissionInfo, setShowSubmissionInfo] = useState(false);
  const [showInviteSent, setShowInviteSent] = useState(false);

  useEffect(() => {
    if (!apiFetching && onMounting) {
      setOnMounting(false);
    }
    if (apiSuccess) {
      dispatch(da.buyers.crud.apiSuccess(false));
    }
  }, [apiFetching, apiError, apiSuccess]);

  const handleOnMount = () => {
    setOnMounting(true);
    if (props.buyerId) {
      dispatch(da.buyers.crud.getBuyerById(props.buyerId));
    }
  };

  const clearAsyncState = () => {
    dispatch(da.buyers.crud.apiSuccess(false));
    dispatch(da.buyers.crud.apiFetching(false));
    dispatch(
      da.buyers.crud.apiError({
        status: 0,
        message: "",
      }),
    );
  };

  useEffect(() => {
    clearAsyncState();
    handleOnMount();
    return () => {
      clearAsyncState();
      dispatch(da.buyers.crud.clearUpsertBuyer());
    };
  }, []);

  const applicationStatus = apiBuyer?.creditInfo.creditAppStatus;
  useEffect(() => {
    if (!applicationStatus) {
      setShowRequestInfoMsg(true);
    } else if (applicationStatus === CreditAppStatusType.invited) {
      setShowRequestInfoMsg(false);
      setShowSubmissionInfo(true);
    } else if (applicationStatus === CreditAppStatusType.pending) {
      setShowRequestInfoMsg(false);
      setShowInviteSent(true);
    }
  }, [applicationStatus]);

  const renderContent = () => {
    if (onMounting)
      return (
        <Stack
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "50vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StitchLoading size="large" />
        </Stack>
      );
    if (showRequestInfoMsg)
      return (
        <RequestInfoMsg
          companyName={apiBuyer?.companyName || "Buyer"}
          onContinue={() => {
            setShowRequestInfoMsg(false);
            setShowCreditForm(true);
          }}
        />
      );
    if (showCreditForm)
      return (
        <CreditInfoForm
          buyer={apiBuyer}
          onInviteCreated={(emailSent) => {
            setShowCreditForm(false);
            if (!emailSent) {
              setShowSubmissionInfo(true);
            } else {
              setShowInviteSent(true);
            }
          }}
        />
      );
    if (showInviteSent)
      return (
        <InviteSent
          companyName={apiBuyer?.companyName || "Buyer"}
          onClose={() => {
            setShowInviteSent(false);
            props.onClose();
          }}
        />
      );
    if (showSubmissionInfo)
      return (
        <SubmissionInfo
          app={apiApp}
          onClose={() => {
            setShowSubmissionInfo(false);
            props.onClose();
          }}
        />
      );
    return (
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "50vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StitchLoading size="large" />
      </Stack>
    );
  };

  return (
    <Dialog
      open
      onClose={props.onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      aria-labelledby="create-edit-buyer-title"
      PaperProps={{
        sx: {
          height: "80vh",
          width: "100%",
          maxWidth: "600px",
          maxHeight: "830px",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <FadeInStack
          spacing={3}
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {renderContent()}
        </FadeInStack>
      </DialogContent>
    </Dialog>
  );
}
