import { RawTimeZone, getTimeZones } from "@vvo/tzdb";

type TimeZone = {
  label: string;
  name: string;
  offset: number;
  continentName: string;
};
type TimeZoneMemo = { [key: string]: TimeZone };

export function getTimeZonesByCode(code: string): TimeZone[] {
  return getTimeZones()
    .filter((tz) => tz.countryCode === code)
    .map((tz) => ({
      label: labelForTimezone(tz),
      name: tz.name,
      offset: tz.rawOffsetInMinutes,
      continentName: tz.continentName,
    }))
    .sort((a, b) => {
      const continentCmp = a.continentName.localeCompare(b.continentName);
      if (continentCmp === 0) {
        return b.offset - a.offset;
      }

      return continentCmp;
    });
}

export function sortTimeZones(a: TimeZone, b: TimeZone): number {
  const continentCmp = a.continentName.localeCompare(b.continentName);
  if (continentCmp === 0) {
    return a.offset - a.offset;
  }
  return continentCmp;
}

export function getAllTimeZones(): TimeZone[] {
  return getTimeZones().map((tz) => ({
    label: labelForTimezone(tz),
    name: tz.name,
    offset: tz.rawOffsetInMinutes,
    continentName: tz.continentName,
  }));
}

export function getDedupedTimeZones(): TimeZone[] {
  type StringToNum = {
    [key: string]: number;
  };
  const continentMap: StringToNum = {};
  const getKey = (tz: RawTimeZone) =>
    `${tz.alternativeName}_${tz.rawOffsetInMinutes / 60}`;
  const deduped: RawTimeZone[] = [];
  const allTZs = getTimeZones();
  allTZs.forEach((tz) => {
    if (continentMap[getKey(tz)] !== 1) {
      continentMap[getKey(tz)] = 1;
      deduped.push(tz);
    }
  });
  return deduped.map((tz: RawTimeZone) => ({
    label: labelForTimezone(tz),
    name: tz.name,
    offset: tz.rawOffsetInMinutes,
    continentName: tz.continentName,
  }));
}

export function getUsTimeZones(): TimeZone[] {
  const allUsTimezones = getTimeZonesByCode("US");
  const timezoneMemo = allUsTimezones.reduce((memo, curr) => {
    if (!memo[curr.label]) {
      memo[curr.label] = curr; // eslint-disable-line no-param-reassign
    }
    return memo;
  }, {} as TimeZoneMemo);

  const usTimezones = Object.keys(timezoneMemo)
    .map((key) => timezoneMemo[key])
    .sort((a, b) => a.label.localeCompare(b.label));

  return usTimezones;
}

export function findTimezoneLabel(timezone: string): string {
  const timeZones = getAllTimeZones();
  const result = timeZones.find((tz) => tz.name === timezone)?.label;
  return result || "";
}

function labelForTimezone(tz: RawTimeZone): string {
  const flag = tz.rawOffsetInMinutes < 0 ? "" : "+";
  return `${tz.alternativeName} (${tz.abbreviation}, GMT${flag}${
    tz.rawOffsetInMinutes / 60
  })`;
}
