import {
  CreditAppStatusType,
  CreditEvalStatusType,
} from "@pd/redux/types/dbTypes";

export default function formatBuyerCreditStatus(
  app: CreditAppStatusType,
  decision: CreditEvalStatusType,
) {
  const composite = `${decision || "undefined"}-${app || "undefined"}`;
  switch (composite) {
    case "approved-invited":
      return "Approved / Invited";
    case "approved-pending":
      return "Approved / Reviewing";
    case "approved-undefined":
    case "approved-complete":
      return "Approved";
    case "rejected-invited":
      return "Denied / Invited";
    case "rejected-pending":
      return "Denied / Reviewing";
    case "rejected-undefined":
    case "rejected-complete":
      return "Denied";
    case "undefined-invited":
      return "Invited";
    case "undefined-complete":
    case "undefined-pending":
      return "Reviewing";
    default:
      return "";
  }
}

/*
| CreditEvalStatusType | CreditAppStatusType | Result                                             |
|----------------------|---------------------|----------------------------------------------------|
| **approved**         | **invited**         | Credit approved; awaiting application submission   |
| **approved**         | **pending**         | Credit approved; application under review          |
| **approved**         | **complete**        | Credit approved; application process completed     |
| **approved**         | **undefined**       | Credit approved; application status unknown        |
| **rejected**         | **invited**         | Credit rejected; awaiting application submission   |
| **rejected**         | **pending**         | Credit rejected; application under review          |
| **rejected**         | **complete**        | Credit rejected; application process completed     |
| **rejected**         | **undefined**       | Credit rejected; application status unknown        |
| **undefined**        | **invited**         | Credit evaluation pending; application invited     |
| **undefined**        | **pending**         | Credit evaluation pending; application under review|
| **undefined**        | **complete**        | Credit evaluation pending; application completed   |
| **undefined**        | **undefined**       | Credit and application status unknown = ERROR      |

Explanation:
- When CreditEvalStatusType is `approved`:
  - The credit evaluation has been approved.
  - The result varies based on the application status.
- When CreditEvalStatusType is `rejected`:
  - The credit evaluation has been rejected.
  - The result highlights that despite the rejection, the application may still be in progress.
- When CreditEvalStatusType is `undefined`:
  - The credit evaluation has not yet been conducted.
  - The result focuses on the application status while noting the pending credit evaluation.
*/
