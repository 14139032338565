import { Stack, Typography, Divider, Button } from "@mui/material";

import { AddressDbType, CreditAppSectionDbType } from "@pd/redux/types/dbTypes";
import formatNamePosessive from "@pd/utils/formatNamePosessive";

type Props = {
  app: CreditAppSectionDbType | null;
  onClose: () => void;
};

export default function SubmissionInfo(props: Props) {
  return (
    <>
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          maxHeight: "830px",
          justifyContent: "flex-center",
          alignItems: "center",
        }}
        gap={2}
      >
        <Stack gap={3} sx={{ mb: 3 }}>
          <Typography variant="h4">
            {formatNamePosessive(props.app?.companyName || "")} credit review
            profile
          </Typography>
          <Typography variant="h4">
            We will follow up on next steps and any request for additional
            documents or information.
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">Company</Typography>
          <Typography>{props.app?.companyName}</Typography>
        </Stack>
        <StitchDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">
            {getTaxIdTitle(props.app?.taxId)}
          </Typography>
          <Typography>{props.app?.taxId || "---"}</Typography>
        </Stack>
        <StitchDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">Website</Typography>
          <Typography>{props.app?.website || "---"}</Typography>
        </Stack>
        <StitchDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">Address</Typography>
          {showAddress(props.app?.address) ? (
            <Stack alignItems="flex-end">
              <Typography>{props.app?.address?.line1 || ""}</Typography>
              {props.app?.address.line2 ? (
                <Typography>{props.app?.address?.line2}</Typography>
              ) : null}
              <Typography>
                <span>
                  {props.app?.address?.city
                    ? `${props.app?.address?.city}, `
                    : ""}
                </span>
                &nbsp;
                <span>{props.app?.address?.state || ""}</span>&nbsp;
                <span>{props.app?.address?.postalCode}</span>
              </Typography>
            </Stack>
          ) : (
            "---"
          )}
        </Stack>
        <StitchDivider />
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <Button
          onClick={props.onClose}
          color="primary"
          variant="contained"
          style={{
            width: "100%",
            height: "56px",
            padding: "16px 24px",
            fontSize: "20px",
          }}
        >
          Done
        </Button>
      </Stack>
    </>
  );
}

function StitchDivider() {
  return (
    <Divider
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      }}
    />
  );
}

function getTaxIdTitle(taxId: string | undefined): string {
  if (!taxId) return "Tax ID";
  const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
  const einRegex = /^\d{2}-\d{7}$/;
  if (ssnRegex.test(taxId)) return "SSN";
  if (einRegex.test(taxId)) return "EIN";
  return "Tax ID";
}

function showAddress(address: AddressDbType | undefined) {
  if (!address) return false;
  return address.line1 || address.city || address.state || address.postalCode;
}
