import { Stack, Typography } from "@mui/material";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FadeInStack } from "@pd/components/FadeComponents";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import formatNamePosessive from "@pd/utils/formatNamePosessive";

type Props = {
  companyName: string;
  onContinue: () => void;
};

export default function RequestInfoMsg(props: Props) {
  return (
    <FadeInStack
      spacing={3}
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        gap={2}
      >
        <Typography variant="h4">Review</Typography>
        <Typography variant="h4">
          Verify {formatNamePosessive(props.companyName)} business standing with
          a credit review.
        </Typography>
        <Typography variant="h4">
          You can request information from {props.companyName} for this review,
          or provide it yourself. {props.companyName} will not be notified of
          the credit review if you choose to submit it on your own, and will not
          receive a report of the result.
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        gap={1}
      >
        <StitchAsyncButton
          buttonText={"Continue"}
          buttonWidth={"100%"}
          endAdornment={<ArrowRightOutlined style={{ fontSize: "24px" }} />}
          success={false}
          loading={false}
          color={"primary"}
          variant={"contained"}
          logoColor={"white"}
          loadingSize={"small"}
          loadingPosition={{ top: -40, left: 0 }}
          onClick={() => props.onContinue()}
          style={{
            height: "56px",
            padding: "16px 24px",
            fontSize: "20px",
          }}
        />
      </Stack>
    </FadeInStack>
  );
}
