import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { CreditAppRequestorFormType } from "@pd/layouts/MktplaceDashboard/types/credit";
import { CreditAppSectionDbType } from "@pd/redux/types/dbTypes";

export type DashSubmitCreditAppAsReqResType =
  | { data: CreditAppSectionDbType }
  | ErrorResType;
export const fetchSubmitCreditAppAsReq = async (
  creditApp: CreditAppRequestorFormType,
  sendEmail: boolean,
  buyerId: string,
): Promise<DashSubmitCreditAppAsReqResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers/${buyerId}/credit/application?send_email=${sendEmail}`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(creditApp),
    },
  );
  return response;
};
