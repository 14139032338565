import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { CreditAppRequestorFormType } from "@pd/layouts/MktplaceDashboard/types/credit";
import {
  AddressDbType,
  BuyerDetailDbType,
  CreditAppSectionDbType,
} from "@pd/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

const creditActions = {
  apiFetching: createAction(
    "dashboard/credit/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/credit/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/credit/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  submit: createAction(
    "dashboard/credit/submit",
    (
      data: CreditAppRequestorFormType,
      sendEmail: boolean,
      buyerId: string,
    ) => ({
      payload: { data, sendEmail, buyerId },
    }),
  ),
  setUiData: createAction(
    "dashboard/credit/setUiData",
    (key: string, value: string | AddressDbType) => ({
      payload: { key, value },
    }),
  ),
  setApiData: createAction(
    "dashboard/credit/setApiAppInfo",
    (data: CreditAppSectionDbType) => ({
      payload: { data },
    }),
  ),
  clearState: createAction("dashboard/credit/clearState"),
  hydrateUiApp: createAction(
    "dashboard/credit/hydreateUiApp",
    (dbBuyer: BuyerDetailDbType) => ({
      payload: { dbBuyer },
    }),
  ),
};

export default creditActions;
