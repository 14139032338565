export function isBuyerPortal() {
  return process.env.STITCH_APP === "portal";
}

export function isBuyerCredit() {
  return process.env.STITCH_APP === "portal";
}

export function isMerchantDash() {
  return process.env.STITCH_APP === "dashboard";
}

export function isTest() {
  return process.env.NODE_ENV === "test";
}

export function getStitchApp() {
  return process.env.STITCH_APP;
}

export function getCookieName() {
  const app = getStitchApp()?.toUpperCase();
  let env = process.env.STITCH_ENV;
  switch (env) {
    case "dev":
      env = "DEVELOPMENT";
      break;
    case "prod":
      env = "PRODUCTION";
      break;
    case "staging":
      env = "STAGING";
      break;
    case "sandbox":
      env = "SANDBOX";
      break;
    default:
      throw new Error("Invalid STITCH_ENV");
  }
  return `STITCH_${app}_AUTHORIZATION_${env}_LOGIN`;
}
