/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncStateType } from "@pd/redux/types";
import { AddressDbType } from "@pd/redux/types/dbTypes";

export type RouteStateType = {
  nextRoute: string;
};

export type CreditApplicationStateType = AsyncStateType & {
  data: {
    businessName: string;
    ein: string;
    website: string;
    address: AddressDbType;
    status: string;
    isBankLinked: boolean;
  };
};

export type BuyerCreditStateType = {
  routes: RouteStateType;
  creditApplication: CreditApplicationStateType;
};

export function isBuyerCreditState(
  state: any,
): state is { buyerCredit: BuyerCreditStateType } {
  return "buyerCredit" in state;
}
