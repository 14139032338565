import { createAction } from "@reduxjs/toolkit";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";

const plaidEventsActions = {
  setPlaidLinkSuccess: createAction(
    "plaidLink/events/setPlaidLinkSuccess",
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => ({
      payload: { publicToken, metadata },
    }),
  ),
  clearPlaidLinkSuccess: createAction("plaidLink/events/clearPlaidLinkSuccess"),
  setPlaidLinkExit: createAction(
    "plaidLink/events/setPlaidLinkExit",
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => ({
      payload: { error, metadata },
    }),
  ),
  clearPlaidLinkExit: createAction("plaidLink/events/clearPlaidLinkExit"),
  setPlaidLinkEvent: createAction(
    "plaidLink/events/setPlaidLinkEvent",
    (eventName: string, metadata: PlaidLinkOnEventMetadata) => ({
      payload: { eventName, metadata },
    }),
  ),
  clearPlaidLinkEvent: createAction("plaidLink/events/clearPlaidLinkEvent"),
};

export default plaidEventsActions;
