import { addFilterQueryParams } from "@pd/api/utils";
import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";
import { InviteInfoReqPayload } from "@pd/layouts/MktplaceDashboard/types/vendors";

import {
  VendorInfoDbType,
  VendorDbType,
  VendorPayoutsDbType,
  VendorOrdersDbType,
  VendorsSearchDbType,
} from "@pd/redux/types/dbTypes";

export type VendorsResType = { data: VendorDbType[] } | ErrorResType;
export const fetchVendors = async (
  filters: TableAllFiltersType,
): Promise<VendorsResType> => {
  const response = await safeFetch(
    addFilterQueryParams(
      `${endpoints.stitchBackend}/dashboard/vendors`,
      filters,
    ),
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DashboardVendorsSearchResType =
  | { data: VendorsSearchDbType[] }
  | ErrorResType;
export const fetchDashboardVendorsSearch = async (
  search: string,
): Promise<DashboardVendorsSearchResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/vendors/search?q=${search}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  if (response.data.length) {
    return {
      ...response,
      data: response.data.map(
        (d: { id: string; firstName: string; lastName: string }) => ({
          id: d.id,
          name: `${d.firstName} ${d.lastName}`,
        }),
      ),
    };
  }
  return response;
};

export type ConfirmVendorPayoutResType = { data: object } | ErrorResType;
export const fetchConfirmVendorPayout = async (
  vendorId: string,
): Promise<ConfirmVendorPayoutResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${vendorId}/payout`;
  const response = await safeFetch(url, {
    method: "POST",
    credentials: "include",
  });
  return response;
};

export type GetVendorInfoByIdResType =
  | { data: VendorInfoDbType }
  | ErrorResType;
export const fetchGetVendorById = async (
  vendorId: string,
): Promise<GetVendorInfoByIdResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${vendorId}`;
  const response = await safeFetch(url, {
    method: "GET",
    credentials: "include",
  });
  return response;
};

export type GetOrdersByVendorIdResType =
  | { data: VendorOrdersDbType[] }
  | ErrorResType;
export const fetchOrdersByVendorId = async (
  vendorId: string,
): Promise<GetOrdersByVendorIdResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${vendorId}/orders`;
  const response = await safeFetch(url, {
    method: "GET",
    credentials: "include",
  });
  return response;
};

export type GetPayoutsByVendorIdResType =
  | { data: VendorPayoutsDbType[] }
  | ErrorResType;
export const fetchPayoutsByVendorId = async (
  vendorId: string,
): Promise<GetPayoutsByVendorIdResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${vendorId}/payouts`;
  const response = await safeFetch(url, {
    method: "GET",
    credentials: "include",
  });
  return response;
};

export type VendorLinkResType = { data: { link: string } } | ErrorResType;
export const fetchSendVendorInvite = async (
  vendorInfo: InviteInfoReqPayload,
  vendorId?: string,
  /* 
    NOTE -- this should not include a vendor id
    The /vendors/invite endpoint is meant to create an invitation
    when there is no internal record (ie stitch collects everything).

    In that case, there is no vendor id to include. 

    This would happen to work because there also is a 
    /vendors/id/invite endpoint to end an invite to an existing
    vendor. However, it doesn't accept a body.
  */
): Promise<VendorLinkResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${
    vendorId ? `/${vendorId}` : ""
  }invite`;
  const response = await safeFetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      first_name: vendorInfo.firstName,
      last_name: vendorInfo.lastName,
      email: vendorInfo.email,
      reference_id: vendorInfo.referenceId,
    }),
  });
  return response;
};

export type VendorLoginLinkResType = { data: { link: string } } | ErrorResType;
export const fetchSendVendorLoginLink = async (
  vendorId?: string,
): Promise<VendorLoginLinkResType> => {
  const url = `${endpoints.stitchBackend}/dashboard/vendors/${vendorId}/invite`;
  const response = await safeFetch(url, {
    method: "POST",
    credentials: "include",
  });
  return response;
};
