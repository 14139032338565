import { CreditApplicationResType } from "@pd/api/buyerCredit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AddressDbType } from "@pd/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

const creditApplicationActions = {
  apiFetching: createAction(
    "buyer/credit/application/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "buyer/credit/application/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "buyer/credit/application/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  clearAsyncState: createAction("buyer/credit/application/clearAsyncState"),
  updateCreditApplication: createAction(
    "buyer/credit/application/updateCreditApplication",
  ),
  setBusinessName: createAction(
    "buyer/credit/application/setBusinessName",
    (value: string) => ({
      payload: { value },
    }),
  ),
  clearBusinessName: createAction("buyer/credit/application/clearBusinessName"),
  setEin: createAction("buyer/credit/application/setEin", (value: string) => ({
    payload: { value },
  })),
  clearEin: createAction("buyer/credit/application/clearEin"),
  setWebsite: createAction(
    "buyer/credit/application/setWebsite",
    (value: string) => ({
      payload: { value },
    }),
  ),
  clearWebsite: createAction("buyer/credit/application/clearWebsite"),
  setAddress: createAction(
    "buyer/credit/application/setAddress",
    (value: AddressDbType) => ({
      payload: { value },
    }),
  ),
  clearAddress: createAction("buyer/credit/application/clearAddress"),
  setIsBankLinked: createAction(
    "buyer/credit/application/setIsBankLinked",
    (value: boolean) => ({
      payload: { value },
    }),
  ),
  fetchCreditApplication: createAction(
    "buyer/credit/application/fetchCreditApplication",
  ),
  hydrateCreditApplication: createAction(
    "buyer/credit/application/hydrateCreditApplication",
    (value: CreditApplicationResType) => ({
      payload: { value },
    }),
  ),
  submitCreditApplication: createAction(
    "buyer/credit/application/submitCreditApplication",
    (publicToken?: string, accountIds?: string[]) => ({
      payload: { publicToken, accountIds },
    }),
  ),
};
export default creditApplicationActions;
