import { ErrorPayload } from "@pd/api/utils/safeFetch";
import tracer from "@pd/tracing";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

export default function createTracerMiddleware() {
  return (_: any) => (next: any) => (action: any) => {
    if (action.type && action.type.includes("apiError")) {
      const isLocalError = action.payload?.error?.status === MANUAL_ERROR_CODE;
      if (isLocalError) {
        const { payload }: { payload: { error: ErrorPayload } } = action;
        tracer.captureException(
          payload.error,
          tracer.ids.critical.LOCAL_API_ERROR,
        );
      }
    } else if (action.type && action.type.includes("setLoggedIn")) {
      const { payload }: { payload: { loggedIn: boolean } } = action;
      tracer.setSessionAttrs({ isLoggedIn: payload.loggedIn });
    } else if (action.type && action.type.includes("logout")) {
      tracer.setSessionAttrs({ isLoggedIn: false });
    }
    return next(action);
  };
}
