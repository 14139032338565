import { CriticalIdsType, DomainIdsType } from "./types";

export const critical: CriticalIdsType = {
  // NOTE: See ./NOTES.md #1 for usecase info.
  LOCAL_API_ERROR: "Local-Api-Error",
  CAUGHT_ERROR: "Caught-Error",
  ERROR_BOUNDARY: "Error-Boundary",
};

export const domain: DomainIdsType = {
  // NOTE: See ./NOTES.md #2 for usecase info.
  API: {
    default: "Api",
    safeFetch: "Api-Safe-Fetch",
  },
  SAGAS: {
    default: "Sagas",
    auth: "Sagas-Auth",
    buyers: "Sagas-Buyers",
    phone: "Sagas-Phone",
    kyb: "Sagas-Kyb",
    kyc: "Sagas-Kyc",
    credit: "Sagas-Credit",
    orders: "Sagas-Orders",
  },
  REDUCERS: {
    default: "Reducers",
    auth: "Reducers-Auth",
  },
  SELECTORS: {
    default: "Selectors",
    auth: "Selectors-Auth",
  },
  COMPONENTS: {
    default: "Components",
    login: "Components-Login",
  },
};

export type IDsType = {
  critical: CriticalIdsType;
  domain: DomainIdsType;
};

const ids: IDsType = {
  critical,
  domain,
};

export default ids;
