import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import formatPhoneNumberDashes from "@pd/utils/formatPhoneNumberDashes";
import { validatePhone } from "@pd/utils/validation";
import { useRef, useState } from "react";

type Props = {
  value?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (keyName: string, value: string, isValid: boolean) => void;
  validator?: (
    value: string,
    errorMsgHandler: (errorMsg: string) => void,
  ) => boolean;
};

export default function PhoneInputSection({
  disabled = false,
  required = false,
  value = "",
  validator = undefined,
  ...props
}: Props) {
  const usPhoneRef = useRef<HTMLDivElement>(null);
  const [showError, setShowError] = useState(false);
  const [validationError, setValidationError] = useState("");
  const theme = useTheme();

  const handleOnBlur = () => {
    if (!value) {
      if (required) {
        setValidationError("This field is required");
        setShowError(true);
      }
      return;
    }
    if (!validator) {
      setValidationError("");
      setShowError(false);
      return;
    }
    const valid = validator(value, (errorMsg) => {
      setValidationError(errorMsg);
    });
    setShowError(!valid);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.currentTarget.value;
    if (
      [
        "inputType" in e.nativeEvent &&
          e.nativeEvent.inputType === "deleteContentBackward",
      ].some(Boolean)
    ) {
      return;
    }

    const isValid = validatePhone(newVal, (errorMsg: string) => {
      setValidationError(errorMsg);
    });

    if (!newVal || /^\D+$/.test(newVal)) {
      const nextnewVal = newVal.replace(/\D/g, "");
      props.onChange("phone", nextnewVal, isValid);
    } else if (newVal.length <= 12) {
      props.onChange("phone", newVal, isValid);
    }
  };

  const handleOnKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const newValue = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = newValue.slice(0, -1);
      if (newValue.slice(-1) === "-") {
        nextValue = newValue.slice(0, -2);
      }
      const isValid = validatePhone(nextValue, (errorMsg: string) => {
        setValidationError(errorMsg);
      });
      props.onChange("phone", nextValue, isValid);
    }
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
      }}
    >
      <InputLabel htmlFor={`outlinedInput-phone`}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography>Phone SMS</Typography>
          {required && (
            <Typography variant="subtitle2" sx={{ pt: "6px" }}>
              *
            </Typography>
          )}
        </Stack>
      </InputLabel>
      <FormControl fullWidth>
        <Stack direction="row">
          <Stack
            ref={usPhoneRef}
            alignItems="center"
            justifyContent="center"
            className="usPhone-input"
            sx={{
              width: "65px",
              height: "50px",
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.lighter,
              border: `1px solid ${
                showError
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              borderRadius: "4px 0 0 4px",
              borderRight: "none",
              fontSize: "16px",
            }}
          >
            US +1
          </Stack>
          <OutlinedInput
            id={`outlinedInput-phone`}
            name={`outlinedInput-phone`}
            disabled={disabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={formatPhoneNumberDashes(value)}
            onKeyDown={handleOnKeydown}
            placeholder="555-555-1234"
            sx={{
              border: showError ? `1px solid ${theme.palette.error.main}` : "",
              borderRadius: "0 4px 4px 0",
              width: "100%",
            }}
          />
        </Stack>
      </FormControl>
      <Typography
        variant="subtitle1"
        color="error"
        sx={{
          pt: "5px",
          pl: "10px",
          display: showError ? "block" : "none",
          lineHeight: "16px",
        }}
      >
        {validationError}
      </Typography>
    </Stack>
  );
}
