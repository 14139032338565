import { combineReducers } from "@reduxjs/toolkit";
import startupReducer from "@pd/redux/reducers/startup";
import plaidLinkReducer from "@pd/components/PlaidLink/redux/reducers";
import dashboardReducer from "@pd/layouts/MktplaceDashboard/redux/reducers";
import { isBuyerCredit, isMerchantDash, isTest } from "@pd/utils/appCheck";
import buyerPortalModalReducer from "@pd/layouts/BuyerPortal/redux/reducers/modalReducer";
import buyerPortalRouteReducer from "@pd/layouts/BuyerPortal/redux/reducers/routeReducer";
import buyerPortalReducer from "@pd/layouts/BuyerPortal/redux/reducers";
import buyerCreditReducer from "@pd/layouts/BuyerCredit/redux/reducers";

export type AppStateType = {
  startup: ReturnType<typeof startupReducer>;
  plaidLink: ReturnType<typeof plaidLinkReducer>;

  // Optional Reducers
  portal?: ReturnType<typeof buyerPortalReducer>;
  modals?: ReturnType<typeof buyerPortalModalReducer>;
  routes?: ReturnType<typeof buyerPortalRouteReducer>;
  dashboard?: ReturnType<typeof dashboardReducer>;
};

export type BuyerPortalReducersType = {
  portal: typeof buyerPortalReducer;
  modals: typeof buyerPortalModalReducer;
  routes: typeof buyerPortalRouteReducer;
};

export type BuyerCreditReducersType = {
  buyerCredit: typeof buyerCreditReducer;
};

export type MerchantDashReducersType = {
  dashboard: typeof dashboardReducer;
};

export default function getReducersByApp() {
  const commonReducers = {
    startup: startupReducer,
    plaidLink: plaidLinkReducer,
  };

  let appReducers:
    | BuyerPortalReducersType
    | MerchantDashReducersType
    | BuyerCreditReducersType;
  if (isBuyerCredit()) {
    appReducers = {
      buyerCredit: buyerCreditReducer,
    };
  } else if (isMerchantDash()) {
    appReducers = {
      dashboard: dashboardReducer,
    };
  } else if (isTest()) {
    appReducers = {
      portal: buyerPortalReducer,
      routes: buyerPortalRouteReducer,
      modals: buyerPortalModalReducer,
      dashboard: dashboardReducer,
    };
  } else {
    throw new Error(`No reducers for app: ${process.env.STITCH_APP}`);
  }
  const reducers = {
    ...commonReducers,
    ...appReducers,
  };
  return combineReducers(reducers);
}
