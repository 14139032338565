import { FadeInStack } from "@pd/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { useEffect, useState } from "react";
import {
  selectUiBuyer,
  selectApiBuyer,
  selectCrudBuyerError,
  selectCrudBuyerFetching,
  selectCrudBuyerSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/crudBuyer";
import { Stack, Typography, Dialog, DialogContent } from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";
import { AddressDbType } from "@pd/redux/types/dbTypes";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import {
  validateEmail,
  validatePhone,
  validateWebsite,
} from "@pd/utils/validation";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import StringInputSection from "@pd/components/StringInputSection";
import StatusMessage from "@pd/components/ErrStatusMessage";
import PhoneInputSection from "@pd/components/PhoneInputSection";

type Props = {
  onSetBuyerId: (id: string) => void;
  onBuyerCreated: () => void;
  onClose: () => void;
};

export default function CreateEditBuyer(props: Props) {
  const dispatch = useAppDispatch();

  const apiFetching = useAppSelector(selectCrudBuyerFetching);
  const apiError = useAppSelector(selectCrudBuyerError);
  const apiSuccess = useAppSelector(selectCrudBuyerSuccess);
  const apiBuyer = useAppSelector(selectApiBuyer);
  const uiBuyer = useAppSelector(selectUiBuyer);

  const [onMounting, setOnMounting] = useState(true);
  const [saveFetching, setSaveFetching] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [uiErrors, setUiErrors] = useState({
    companyName: false,
    extId: false,
    website: false,
    email: false,
    phone: false,
  });

  const hasUiErrors = () =>
    uiErrors.companyName ||
    uiErrors.website ||
    uiErrors.extId ||
    uiErrors.phone ||
    uiErrors.email;

  const handleFetchBuyerById = () => {
    setOnMounting(true);
    dispatch(da.buyers.crud.clearUpsertBuyer());
  };

  const clearAsyncState = () => {
    dispatch(da.buyers.crud.apiSuccess(false));
    dispatch(da.buyers.crud.apiFetching(false));
    dispatch(
      da.buyers.crud.apiError({
        status: 0,
        message: "",
      }),
    );
  };

  useEffect(() => {
    clearAsyncState();
    handleFetchBuyerById();
    return () => {
      dispatch(da.buyers.crud.clearUpsertBuyer());
      clearAsyncState();
    };
  }, []);

  useEffect(() => {
    if (apiSuccess && !saveSuccess && saveFetching) {
      setSaveSuccess(true);
      setSaveFetching(false);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
    if (apiError.status && saveFetching) {
      setSaveFetching(false);
    } else if (!apiFetching && onMounting) {
      setOnMounting(false);
    } else if (!apiFetching && saveFetching) {
      setSaveFetching(false);
    }
  }, [apiFetching, apiError, apiSuccess, saveSuccess, saveFetching]);

  const buyerID = apiBuyer?.id;
  useEffect(() => {
    if (saveSuccess && buyerID) {
      props.onSetBuyerId(buyerID);
      props.onBuyerCreated();
    }
  }, [saveSuccess, buyerID]);

  function handleOnChange(
    keyName: string,
    value: string | AddressDbType,
    isValid: boolean,
  ) {
    setUiErrors((prev) => ({ ...prev, [keyName]: !isValid }));
    dispatch(da.buyers.crud.apiError({ message: "", status: 0 }));
    dispatch(da.buyers.crud.setBuyerUiData(keyName, value));
  }

  function shouldDisableConfirmBuyer() {
    return (
      hasUiErrors() ||
      !uiBuyer?.companyName ||
      !uiBuyer?.extId ||
      !uiBuyer?.email ||
      !uiBuyer?.phone
    );
  }

  function onConfirmBuyer() {
    if (shouldDisableConfirmBuyer()) {
      dispatch(
        da.buyers.crud.apiError({
          message: "Please fill out all fields",
          status: MANUAL_ERROR_CODE,
        }),
      );
      return;
    }
    if (uiBuyer == null) {
      return;
    }
    setSaveSuccess(false);
    setSaveFetching(true);
    dispatch(da.buyers.crud.createBuyer(uiBuyer, true));
  }

  if (apiError.status) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <Dialog
      open
      onClose={props.onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      aria-labelledby="create-edit-buyer-title"
      PaperProps={{
        sx: {
          height: "80vh",
          width: "100%",
          maxWidth: "600px",
          maxHeight: "830px",
        },
      }}
    >
      <DialogContent
        sx={{
          p: 4,
        }}
      >
        <FadeInStack
          spacing={3}
          sx={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
            pb: 2,
          }}
        >
          {onMounting ? (
            <Stack
              sx={{
                flexGrow: 1,
                width: "100%",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StitchLoading size="large" />
            </Stack>
          ) : (
            <>
              <Stack
                spacing={3}
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Stack gap={4}>
                  <Typography variant="h4">Add a profile</Typography>
                  <Typography variant="h4">
                    Create a new business profile to enable payments
                  </Typography>
                  <Typography>{""}</Typography>
                </Stack>
                <StringInputSection
                  keyName="companyName"
                  value={uiBuyer?.companyName || ""}
                  label="Company name"
                  placeholder="Company Name"
                  onChange={handleOnChange}
                />
                <StringInputSection
                  keyName="extId"
                  value={uiBuyer?.extId || ""}
                  label="Reference ID"
                  placeholder="Enter a unique reference ID"
                  onChange={handleOnChange}
                />
                <StringInputSection
                  keyName="website"
                  value={uiBuyer?.website || ""}
                  label="Website"
                  placeholder="http://www.example.com"
                  onChange={handleOnChange}
                  validator={validateWebsite}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  sx={{ width: "100%" }}
                >
                  <StringInputSection
                    keyName="email"
                    value={uiBuyer?.email || ""}
                    label="Email"
                    placeholder="example@withstitch.com"
                    onChange={handleOnChange}
                    validator={validateEmail}
                  />
                  <PhoneInputSection
                    value={uiBuyer?.phone || ""}
                    onChange={handleOnChange}
                    validator={validatePhone}
                  />
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 4,
                }}
              >
                <StatusMessage loading={saveFetching} apiError={apiError} />
                <StitchAsyncButton
                  buttonText={"Add"}
                  buttonWidth={"100%"}
                  endAdornment={
                    <ArrowRightOutlined style={{ fontSize: "24px" }} />
                  }
                  success={saveSuccess}
                  loading={saveFetching}
                  color={"primary"}
                  variant={"contained"}
                  logoColor={"white"}
                  loadingSize={"small"}
                  loadingPosition={{ top: -40, left: 0 }}
                  onClick={onConfirmBuyer}
                  style={{
                    height: "56px",
                    padding: "16px 24px",
                    fontSize: "20px",
                  }}
                />
              </Stack>
            </>
          )}
        </FadeInStack>
      </DialogContent>
    </Dialog>
  );
}
