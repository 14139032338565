import type { BuyersColDefType } from "../types";

export const buyersColumns: readonly BuyersColDefType[] = [
  {
    index: 1,
    id: "name",
    label: "Buyer",
  },
  {
    index: 2,
    id: "phone",
    label: "Phone number",
  },
  {
    index: 3,
    id: "extId",
    label: "Reference id",
  },
  {
    index: 4,
    id: "id",
    label: "Buyer id",
  },
  {
    index: 5,
    id: "creditStatus",
    label: "Credit status",
  },
  {
    index: 6,
    id: "creditUsage",
    label: "Credit usage",
  },
];
