import { combineReducers } from "@reduxjs/toolkit";
import { BuyerCreditStateType } from "../../types";
import creditApplicationReducer from "./creditApplication";
import routeReducer from "./routes";

const buyerCreditReducer = combineReducers<BuyerCreditStateType>({
  routes: routeReducer,
  creditApplication: creditApplicationReducer,
});

export default buyerCreditReducer;
