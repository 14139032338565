import { Typography, Stack } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";

type Props = {
  onClose: () => void;
  companyName: string;
};

export default function InviteSent(props: Props) {
  return (
    <FadeInStack
      spacing={3}
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          maxHeight: "830px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Stack gap={3}>
          <Typography variant="h4">Request a review</Typography>
          <Typography variant="h4">
            {props.companyName} has received a request for a credit review.
          </Typography>
          <Typography variant="h4">
            We will notify you when results are ready or if we need additional
            documentation. {props.companyName} will not be notified of the
            results or a request for additional information.
          </Typography>
        </Stack>
      </Stack>
      <StitchAsyncButton
        buttonText={"Done"}
        buttonWidth={"100%"}
        success={false}
        loading={false}
        color={"primary"}
        variant={"contained"}
        logoColor={"black"}
        loadingSize={"small"}
        loadingPosition={{ top: -40, left: 0 }}
        onClick={() => props.onClose()}
        style={{
          height: "56px",
          padding: "16px 24px",
          fontSize: "20px",
        }}
      />
    </FadeInStack>
  );
}
