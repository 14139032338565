import { addFilterQueryParams } from "@pd/api/utils";
import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";

import {
  BuyerTableDbType,
  BuyerDetailDbType,
  BuyersSearchDbType,
  AddressDbType,
} from "@pd/redux/types/dbTypes";
import camelCaseToSnakeCaseKeys from "@pd/utils/camelCaseToSnakeCaseKeys";

export type DashboardBuyersResType =
  | { data: BuyerTableDbType[] }
  | ErrorResType;
export const fetchDashboardBuyers = async (
  filters: TableAllFiltersType,
): Promise<DashboardBuyersResType> => {
  const response = await safeFetch(
    addFilterQueryParams(
      `${endpoints.stitchBackend}/dashboard/buyers`,
      filters,
    ),
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DashboardBuyersSearchResType =
  | { data: BuyersSearchDbType[] }
  | ErrorResType;
export const fetchDashboardBuyersSearch = async (
  search: string,
): Promise<DashboardBuyersSearchResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers/search?q=${search}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DashboardBuyersCreateResType =
  | { data: BuyerDetailDbType }
  | ErrorResType;
export type DashboardBuyersCreateReqType = {
  id?: string;
  companyName: string;
  referenceId: string;
  email: string;
  website: string;
  address: AddressDbType;
  phone: string;
};
export const fetchCreateBuyer = async (
  buyer: DashboardBuyersCreateReqType,
): Promise<DashboardBuyersCreateResType> => {
  const snakeCased = camelCaseToSnakeCaseKeys(buyer);
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(snakeCased),
    },
  );
  return response;
};

export type DashboardBuyerByIdResType =
  | { data: BuyerDetailDbType }
  | ErrorResType;
export const fetchBuyerById = async (
  id: string,
): Promise<DashboardBuyerByIdResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/buyers/${id}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response; // NOTE: See ./README.md #1 for mock response info.
};
