import { all, call, put, takeLeading, select } from "redux-saga/effects";
import {
  fetchOrders,
  fetchDeleteSelectedOrders,
  fetchConfirmSelectedOrders,
  fetchVoidSelectedOrders,
  fetchBulkPayoutInfo,
  fetchTriggerBulkPayout,
  BulkPayoutInfoResType,
  BulkPayoutResultResType,
  OrdersResType,
  SelectedOrdersActionResType,
} from "@pd/api/dashboard/orders";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import {
  selectAllOrders,
  selectAllOrdersFiltered,
  selectSelectedOrders,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/allOrders";
import { OrderDbType } from "@pd/redux/types/dbTypes";
import tracer from "@pd/tracing";

import da from "../../actions";
import { TableAllFiltersType } from "../../../types/tables";
import {
  selectHasTableFilters,
  selectTableFilters,
} from "../../selectors/table";

export default function* allOrderSagas() {
  yield all([
    takeLeading(da.orders.all.getAllOrders.toString(), handleGetOrders),
    takeLeading(
      da.orders.all.deleteSelectedOrders.toString(),
      handleDeleteSelectedOrders,
    ),
    takeLeading(
      da.orders.all.voidSelectedOrders.toString(),
      handleVoidSelectedOrders,
    ),
    takeLeading(
      da.orders.all.confirmSelectedOrders.toString(),
      handleConfirmSelectedOrders,
    ),
    takeLeading(
      da.orders.all.getSelectedOrders.toString(),
      handleSetSelectedOrders,
    ),
    takeLeading(
      da.orders.all.getBulkPayoutsInfo.toString(),
      handleGetBulkPayoutsInfo,
    ),
    takeLeading(
      da.orders.all.triggerBulkPayout.toString(),
      handleTriggerBulkPayouts,
    ),
  ]);
}

export function* handleGetOrders(): Generator<any, string, any> {
  try {
    yield all([
      put(da.orders.all.apiSuccess(true)),
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const filters = yield select(selectTableFilters);
    const res: OrdersResType = yield call(fetchOrders, filters);
    if ("error" in res) {
      tracer.warn(
        "Error fetching dashboard orders",
        tracer.ids.domain.SAGAS.orders,
        { error: res.error.message },
      );
      yield all([
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      if (res.data.length) {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: res.data[0].totalResults,
          }),
        );
      } else {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: 0,
          }),
        );
      }
      yield put(da.orders.all.setAllOrders(res.data));
      yield all([
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(true)),
      ]);
    }
    return "";
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching orders";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.orders.all.apiFetching(false)),
      put(da.orders.all.apiSuccess(false)),
      put(
        da.orders.all.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
    ]);
    return errMsg;
  }
}

/**
 * This function is used to get orders by filter. It's called from the Table
 * Saga (actions.table.getTableData) as a helper function. The Table Saga is responsible for setting the
 * table filters and handling async state (fetching, error, etc.). This function
 * is only responsible for fetching the orders by filter and returning the
 * response.
 * @returns {OrdersResType} - The response from the API
 */
export function* handleApplyFiltersOrdersTable(
  filters: TableAllFiltersType,
): Generator<any, null | string, any> {
  try {
    const res: OrdersResType = yield call(fetchOrders, filters);
    if ("error" in res) {
      tracer.error(
        "Unable to fetch filtered orders",
        tracer.ids.domain.SAGAS.orders,
        { error: res.error.message },
      );
      return res.error.message;
    }
    if (res.data.length) {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: res.data[0].totalResults,
        }),
      );
    } else {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: 0,
        }),
      );
    }
    yield put(da.orders.all.setFilteredOrders(res.data));
    return null;
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching table orders.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    tracer.captureException(new Error(errMsg), tracer.ids.domain.SAGAS.orders);
    return errMsg;
  }
}

export function* handleSetSelectedOrders(
  action: ReturnType<typeof da.orders.all.getSelectedOrders>,
): Generator<any, string | null, any> {
  try {
    const allOrders: OrderDbType[] = yield select(selectAllOrders);
    const filteredOrders: OrderDbType[] = yield select(selectAllOrdersFiltered);
    const hasFilters: boolean = yield select(selectHasTableFilters);
    const { status } = action.payload;

    if (!status) {
      yield put(da.orders.all.setSelectedOrders([]));
      return null;
    }
    const selectedIds: string[] = (hasFilters ? filteredOrders : allOrders)
      .filter((order) => order.status === status)
      .map((order) => order.id);
    yield put(da.orders.all.setSelectedOrders(selectedIds));
    return null;
  } catch (error: unknown) {
    const errMsg = "An error occurred while fetching table orders.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    tracer.captureException(new Error(errMsg), tracer.ids.domain.SAGAS.orders);
    return errMsg;
  }
}

export function* handleConfirmSelectedOrders() {
  let selectedIds: string[] = [];
  try {
    selectedIds = yield select(selectSelectedOrders);
    if (!selectedIds.length) {
      return null;
    }
    yield all([
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiSuccess(false)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const res: SelectedOrdersActionResType = yield call(
      fetchConfirmSelectedOrders,
      selectedIds,
    );
    if ("error" in res) {
      tracer.warn("Unable to confirm orders", tracer.ids.domain.SAGAS.orders, {
        error: res.error.message,
      });
      yield all([
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.all.setSelectedOrders([])),
        put(da.orders.all.apiFetching(false)),
      ]);
      yield put(da.orders.all.getAllOrders());
      if (res.data.successCount === selectedIds.length) {
        tracer.info(
          "Orders confirmed successfully",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(da.orders.all.apiSuccess(true));
      } else {
        tracer.warn(
          "Some orders could not be confirmed",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(
          da.orders.all.apiError({
            message: JSON.stringify({
              selectionCount: res.data.selectionCount,
              successCount: res.data.successCount,
              errorsTotal: res.data.errors?.length,
            }),
            status: MANUAL_ERROR_CODE,
          }),
        );
        if (res.data.successCount) {
          yield put(da.orders.all.apiSuccess(true));
        }
      }
    }
    return null;
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while confirming your selected orders. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    tracer.captureException(
      new Error("Unable to confirm selected orders"),
      tracer.ids.domain.SAGAS.orders,
      { ids: selectedIds.join(",") },
    );
    return errMsg;
  }
}

export function* handleVoidSelectedOrders() {
  let selectedIds: string[] = [];
  try {
    selectedIds = yield select(selectSelectedOrders);
    if (!selectedIds.length) {
      return null;
    }
    yield all([
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiSuccess(false)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const res: SelectedOrdersActionResType = yield call(
      fetchVoidSelectedOrders,
      selectedIds,
    );
    if ("error" in res) {
      tracer.warn("Unable to void orders", tracer.ids.domain.SAGAS.orders, {
        error: res.error.message,
      });
      yield all([
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.all.setSelectedOrders([])),
        put(da.orders.all.apiFetching(false)),
      ]);
      yield put(da.orders.all.getAllOrders());
      if (res.data.successCount === selectedIds.length) {
        tracer.info(
          "Orders voided successfully",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(da.orders.all.apiSuccess(true));
      } else {
        tracer.warn(
          "Some orders could not be voided",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(
          da.orders.all.apiError({
            message: JSON.stringify({
              selectionCount: res.data.selectionCount,
              successCount: res.data.successCount,
              errorsTotal: res.data.errors?.length,
            }),
            status: MANUAL_ERROR_CODE,
          }),
        );
        if (res.data.successCount) {
          yield put(da.orders.all.apiSuccess(true));
        }
      }
    }
    return null;
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while deleting your selected orders. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    tracer.captureException(
      new Error("Unable to void selected orders"),
      tracer.ids.domain.SAGAS.orders,
      { ids: selectedIds.join(",") },
    );
    return errMsg;
  }
}

export function* handleDeleteSelectedOrders() {
  let selectedIds: string[] = [];
  try {
    selectedIds = yield select(selectSelectedOrders);
    if (!selectedIds.length) {
      return null;
    }
    yield all([
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiSuccess(false)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const res: SelectedOrdersActionResType = yield call(
      fetchDeleteSelectedOrders,
      selectedIds,
    );
    if ("error" in res) {
      tracer.warn("Unable to delete orders", tracer.ids.domain.SAGAS.orders, {
        error: res.error.message,
      });
      yield all([
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.all.setSelectedOrders([])),
        put(da.orders.all.apiFetching(false)),
      ]);
      yield put(da.orders.all.getAllOrders());
      if (res.data.successCount === selectedIds.length) {
        tracer.info(
          "Orders deleted successfully",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(da.orders.all.apiSuccess(true));
      } else {
        tracer.warn(
          "Some orders could not be deleted",
          tracer.ids.domain.SAGAS.orders,
          {
            successCount: res.data.successCount,
            selectionCount: res.data.selectionCount,
          },
        );
        yield put(
          da.orders.all.apiError({
            message: JSON.stringify({
              selectionCount: res.data.selectionCount,
              successCount: res.data.successCount,
              errorsCount: res.data.errors?.length,
            }),
            status: MANUAL_ERROR_CODE,
          }),
        );
        if (res.data.successCount) {
          yield put(da.orders.all.apiSuccess(true));
        }
      }
    }
    return null;
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while deleting your selected orders. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    tracer.captureException(
      new Error("Unable to delete selected orders"),
      tracer.ids.domain.SAGAS.orders,
      { ids: selectedIds.join(",") },
    );
    return errMsg;
  }
}

export function* handleGetBulkPayoutsInfo(): Generator<any, string, any> {
  try {
    yield all([
      put(da.orders.all.apiSuccess(false)),
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const res: BulkPayoutInfoResType = yield call(fetchBulkPayoutInfo);
    if ("error" in res) {
      tracer.warn(
        "Unable to fetch bulk payout info",
        tracer.ids.domain.SAGAS.orders,
        { error: res.error.message },
      );
      yield all([
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.all.setBulkPayoutInfo(res.data)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(true)),
      ]);
    }
    return "";
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while fetching bulk payout info. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.orders.all.apiFetching(false)),
      put(da.orders.all.apiSuccess(false)),
      put(
        da.orders.all.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
    ]);
    return errMsg;
  }
}

export function* handleTriggerBulkPayouts(): Generator<any, string, any> {
  try {
    yield all([
      put(da.orders.all.apiSuccess(false)),
      put(da.orders.all.apiFetching(true)),
      put(da.orders.all.apiError({ message: "", status: 0 })),
    ]);
    const res: BulkPayoutResultResType = yield call(fetchTriggerBulkPayout);
    if ("error" in res) {
      tracer.warn(
        "Unable to fetch bulk payout info",
        tracer.ids.domain.SAGAS.orders,
        { error: res.error.message },
      );
      yield all([
        put(da.orders.all.apiError(res.error)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.orders.all.setBulkPayoutResults(res.data)),
        put(da.orders.all.apiFetching(false)),
        put(da.orders.all.apiSuccess(true)),
      ]);
    }
    return "";
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while fetching bulk payout info. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.orders.all.apiFetching(false)),
      put(da.orders.all.apiSuccess(false)),
      put(
        da.orders.all.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
    ]);
    return errMsg;
  }
}
