import { useCallback, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import TableFilterSearch from "@pd/components/DynamicTable/FilterSearch";
import dashboardActions from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectTableError,
  selectTableFilters,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";

type Props = {
  onShowCreateBuyer: (id?: string | undefined) => void;
};
export default function BuyersHeaderActions(props: Props) {
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectTableFetching);
  const apiError = useAppSelector(selectTableError);
  const filterState = useAppSelector(selectTableFilters);
  const [searchFetching, setSearchFetching] = useState(false);

  useEffect(() => {
    if (!apiFetching && searchFetching) {
      setTimeout(() => setSearchFetching(false), 400);
    } else if (apiError.status && searchFetching) {
      setTimeout(() => setSearchFetching(false), 400);
    }
  }, [apiError, apiFetching, searchFetching]);

  const handleOnFilterSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchFetching(true);
      dispatch(dashboardActions.table.setTableSearchFilter(event.target.value));
      dispatch(
        dashboardActions.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "buyers",
          filter: {
            type: "search",
            value: event.target.value,
          },
        }),
      );
    },
    [],
  );

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={10} lg={10.5} sx={{ width: "100%" }}>
        <TableFilterSearch
          value={filterState.search.value}
          onChange={handleOnFilterSearchChange}
        />
      </Grid>
      <Grid item xs={2} lg={1.5}>
        <Button
          variant="contained"
          onClick={() => {
            props.onShowCreateBuyer();
          }}
          sx={{ width: "100%", height: "100%", p: "0px" }}
        >
          Add Buyer
        </Button>
      </Grid>
    </Grid>
  );
}
