import { all, takeLatest, call, put, delay } from "redux-saga/effects";
import { fetchPingCheck } from "@pd/api/dashboard";
import type { PingResType } from "@pd/api/dashboard";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import da from "../actions";
import buyersSaga from "./buyers";
import ordersSaga from "./orders";
import tableSagas from "./table";
import transfersSagas from "./transfers";
import vendorsSaga from "./vendors";
import settingsSaga from "./settings";
import teamsSagas from "./teams";
import creditSaga from "./credit";

export default function* dashboardSaga() {
  yield all([
    takeLatest(da.pingPong.getPingCheck.toString(), onPingCheck),
    buyersSaga(),
    ordersSaga(),
    tableSagas(),
    transfersSagas(),
    vendorsSaga(),
    settingsSaga(),
    teamsSagas(),
    creditSaga(),
  ]);
}

export function* onPingCheck() {
  try {
    yield all([
      put(da.pingPong.apiFetching(true)),
      put(da.pingPong.apiFetching(true)),
    ]);
    const res: PingResType = yield call(fetchPingCheck);
    if ("error" in res) {
      yield all([
        put(da.pingPong.apiError(res.error)),
        put(da.pingPong.apiSuccess(false)),
      ]);
    } else {
      yield put(da.pingPong.setPingCheck(res.data));
      yield delay(400);
      yield all([
        put(da.pingPong.apiFetching(false)),
        put(da.pingPong.apiSuccess(true)),
      ]);
    }
  } catch (error) {
    const errMsg = "An error occurred while checking for Ping.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(da.pingPong.apiFetching(false)),
      put(da.pingPong.apiError({ message: errMsg, status: MANUAL_ERROR_CODE })),
      put(da.pingPong.apiSuccess(false)),
    ]);
  }
}
