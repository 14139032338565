import { createReducer } from "@reduxjs/toolkit";
import routeActions from "@pd/layouts/BuyerCredit/redux/actions/routes";
import { RouteStateType } from "@pd/layouts/BuyerCredit/types";

const initialRouteState: RouteStateType = {
  nextRoute: "",
};

const routeReducer = createReducer<RouteStateType>(
  initialRouteState,
  (builder) => {
    builder
      .addCase(routeActions.redirect, onRedirect)
      .addCase(routeActions.clearNextRoute, onClearNextRoute);
  },
);

export function onRedirect(
  state: RouteStateType,
  action: ReturnType<typeof routeActions.redirect>,
) {
  state.nextRoute = action.payload.route;
}

export function onClearNextRoute(state: RouteStateType) {
  state.nextRoute = "";
}

export default routeReducer;
