/* eslint-disable  import/no-extraneous-dependencies */
import StackTraceGPS from "stacktrace-gps";
import StackTrace from "stacktrace-js";
import StackFrame from "stackframe";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import {
  BatchSpanProcessor,
  WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_TELEMETRY_SDK_LANGUAGE,
  TELEMETRYSDKLANGUAGEVALUES_WEBJS,
} from "@opentelemetry/semantic-conventions";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { trace, Tracer, SpanStatusCode } from "@opentelemetry/api";

import isDevEnv from "@pd/utils/envCheck";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { TracerIDType } from "@pd/tracing/types";
import ids, { IDsType } from "@pd/tracing/constants";

type TracingAttrs = {
  userId: string;
  isLoggedIn: boolean;
};

type AttrArgType = {
  [key: string]: string | number | boolean;
};

const severityMapping: Record<string, { number: number; text: string }> = {
  critical: { number: 24, text: "FATAL" },
  error: { number: 17, text: "ERROR" },
  warn: { number: 13, text: "WARN" },
  info: { number: 9, text: "INFO" },
};

export class Tracing {
  TRACER_ID: string;

  sessionAttrs: TracingAttrs;

  tracer: Tracer;

  ids: IDsType;

  constructor() {
    this.TRACER_ID = `FE-${process.env.STITCH_ENV}_merch-dash`;
    this.sessionAttrs = { userId: "", isLoggedIn: false };
    this.buildTracer();
    this.tracer = trace.getTracer(this.TRACER_ID);
    this.ids = ids;
  }

  getTracerId = (): string => this.TRACER_ID;

  getTracer = (): Tracer => this.tracer;

  setSessionAttrs = ({
    userId,
    isLoggedIn,
  }: {
    userId?: string;
    isLoggedIn?: boolean;
  }) => {
    this.sessionAttrs = {
      userId: userId || this.sessionAttrs.userId || "",
      isLoggedIn: isLoggedIn || this.sessionAttrs.isLoggedIn || false,
    };
  };

  private buildTracer = () => {
    if (isDevEnv()) return;
    const resource = new Resource({
      [SEMRESATTRS_SERVICE_NAME]: this.getTracerId(),
      [SEMRESATTRS_TELEMETRY_SDK_LANGUAGE]: TELEMETRYSDKLANGUAGEVALUES_WEBJS,
    });
    const provider = new WebTracerProvider({ resource });
    const spanProcessor = new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: "https://ingest.us.signoz.cloud:443/v1/traces",
        headers: {
          "signoz-access-token": process.env.SIGNOZ_INGESTION_KEY,
        },
      }),
    );
    provider.addSpanProcessor(spanProcessor);
    provider.register({
      contextManager: new ZoneContextManager(),
    });
  };

  captureException = async (
    error: Error | ErrorPayload,
    _id: TracerIDType,
    _attrs?: AttrArgType,
  ) => {
    if (isDevEnv()) return;
    const id = `exception@${_id}`;
    const span = this.tracer.startSpan(id);
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });

    const attrs = _attrs || [];
    Object.entries(attrs)
      .concat([
        ["env", process.env.STITCH_ENV || "unknown"],
        ["app", process.env.STITCH_APP || "unknown"],
        ["isLoggedIn", this.sessionAttrs.isLoggedIn],
        ["userId", this.sessionAttrs.userId || "unknown"],
        ["log.severity_number", severityMapping.error.number],
        ["log.severity_text", severityMapping.error.text],
      ])
      .filter(([_, value]) => value)
      .forEach(([attr, val]) => {
        span.setAttribute(attr, val);
      });

    let err: Error;
    if (isErrorPayload(error)) {
      err = new Error(error.message);
      err.stack = "Mapped stack trace not available on Manual Error";
    } else {
      err = error;
    }
    try {
      const stackFrames = await StackTrace.fromError(err);
      const gps = new StackTraceGPS();
      const mappedStackFrames = await Promise.all(
        stackFrames.map((frame) =>
          gps.pinpoint(
            new StackFrame({
              isConstructor: frame.isConstructor,
              isEval: frame.isEval,
              isNative: frame.isNative,
              columnNumber: frame.columnNumber,
              lineNumber: frame.lineNumber,
              fileName: frame.fileName,
              functionName: frame.functionName,
              source: frame.source,
              args: frame.args,
            }),
          ),
        ),
      );
      const stackTraceString = mappedStackFrames
        .map(
          (frame) =>
            `${frame.functionName || "anonymous"} (${frame.fileName}:${
              frame.lineNumber
            }:${frame.columnNumber})`,
        )
        .join("\n");
      span.setAttribute("stackTrace", stackTraceString);
    } catch (mappingError) {
      span.setAttribute("stackTrace", err.stack || "");
    }
    span.recordException(err);
    span.end();
  };

  critical = (message: string, _id: TracerIDType, _attrs?: AttrArgType) => {
    this.log(message, "critical", _id, _attrs);
  };

  error = (message: string, _id: TracerIDType, _attrs?: AttrArgType) => {
    this.log(message, "error", _id, _attrs);
  };

  warn = (message: string, _id: TracerIDType, _attrs?: AttrArgType) => {
    this.log(message, "warn", _id, _attrs);
  };

  info = (message: string, _id: TracerIDType, _attrs?: AttrArgType) => {
    this.log(message, "info", _id, _attrs);
  };

  log = (
    message: string,
    lvl: "critical" | "error" | "warn" | "info",
    _id: TracerIDType,
    _attrs?: AttrArgType,
  ) => {
    if (isDevEnv()) return;
    const id = `${lvl}@${_id || "unknown"}`;
    const span = this.tracer.startSpan(id);
    const severityInfo = severityMapping[lvl];
    const attrs = _attrs || {};
    Object.entries(attrs)
      .concat([
        ["env", process.env.STITCH_ENV || "unknown"],
        ["app", process.env.STITCH_APP || "unknown"],
        ["isLoggedIn", this.sessionAttrs.isLoggedIn],
        ["userId", this.sessionAttrs.userId || "unknown"],
        ["log.severity_number", severityInfo.number],
        ["log.severity_text", severityInfo.text],
      ])
      .filter(([_, value]) => value !== undefined)
      .forEach(([attr, val]) => {
        span.setAttribute(attr, val);
      });
    span.addEvent(severityInfo.text, {
      "log.severity_number": severityInfo.number,
      "log.severity_text": severityInfo.text,
      "log.message": message,
      timestamp: Date.now(),
    });
    if (lvl === "error" || lvl === "critical") {
      span.setStatus({ code: SpanStatusCode.ERROR, message });
    }
    span.end();
  };
}

function isErrorPayload(error: Error | ErrorPayload): error is ErrorPayload {
  return (error as ErrorPayload).message !== undefined;
}

const tracer = new Tracing();
export default tracer;
