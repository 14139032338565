import { Alert, Snackbar, Stack, Typography, useTheme } from "@mui/material";
import AuthGuard from "@pd/components/AuthGuard";

import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { FadeInStack } from "@pd/components/FadeComponents";
import { useLocation } from "react-router";
import BuyersTable from "./BuyersTable";
import CreateEditBuyer from "./modals/CreateEditBuyer";
import BuyerDetails from "./modals/BuyerDetails";
import CreditInvite from "./modals/CreditInvite";

export default function Buyers() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const dispatch = useAppDispatch();
  const [showCreateEditBuyer, setShowCreateEditBuyer] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [showBuyerDetails, setOpenBuyerDetails] = useState(false);
  const [showCreditInvite, setOpenCreditInvite] = useState(false);
  const [targetBuyerId, setTargetBuyerId] = useState("");

  const handleOnClose = useCallback(
    ({ buyerCreated }: { buyerCreated: boolean }) => {
      setShowCreateEditBuyer(false);
      setOpenBuyerDetails(false);
      setOpenCreditInvite(false);
      setTargetBuyerId("");
      if (buyerCreated) {
        setShowAlertMsg(true);
        dispatch(da.buyers.all.getBuyers());
      }
    },
    [],
  );

  useEffect(() => {
    dispatch(da.buyers.all.getBuyers());
  }, []);

  useEffect(() => {
    if (id) {
      setTargetBuyerId(id as string);
    }
  }, [id]);

  const theme = useTheme();

  const renderTable = () => (
    <>
      <Stack
        direction={{
          lg: "row",
          xs: "column",
        }}
        justifyContent="space-evenly"
        alignItems="flex-start"
        sx={{ my: "30px" }}
        spacing={2}
      >
        <Typography
          variant="h1"
          sx={{ fontWeight: 500, fontFamily: "CircularBold" }}
        >
          Buyers
        </Typography>
      </Stack>
      <Stack
        sx={{ width: "100%" }}
        key={Date.now() /* NOTE: See ./NOTES.#1 for details */}
      >
        <BuyersTable
          onSetBuyerId={(buyerId) => {
            setOpenBuyerDetails(true);
            setTargetBuyerId(buyerId);
          }}
          onShowCreateBuyer={(newId) => {
            setShowCreateEditBuyer(true);
            if (newId) setTargetBuyerId(newId);
          }}
        />
      </Stack>
    </>
  );

  return (
    <AuthGuard>
      <FadeInStack
        alignItems="flex-start"
        sx={{
          pt: "80px",
          px: "50px",
          width: "100%",
          height: "100%",
        }}
      >
        {renderTable()}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showAlertMsg}
          autoHideDuration={1500}
          onClose={() => setShowAlertMsg(false)}
          sx={{ mb: 5 }}
        >
          <Alert
            onClose={() => setShowAlertMsg(false)}
            severity="info"
            variant="filled"
            sx={{ width: "100%", backgroundColor: theme.palette.primary.main }}
          >
            Buyer created
          </Alert>
        </Snackbar>
        {showCreateEditBuyer ? (
          <CreateEditBuyer
            // buyerId={targetBuyerId}
            onSetBuyerId={setTargetBuyerId}
            onBuyerCreated={() => {
              setShowCreateEditBuyer(false);
              setOpenBuyerDetails(true);
              handleOnClose({ buyerCreated: true });
            }}
            onClose={() => handleOnClose({ buyerCreated: false })}
          />
        ) : null}
        {showBuyerDetails ? (
          <BuyerDetails
            buyerId={targetBuyerId}
            onOpenInvite={() => {
              setOpenBuyerDetails(false);
              setOpenCreditInvite(true);
            }}
            onClose={() => {
              setOpenBuyerDetails(false);
              handleOnClose({ buyerCreated: false });
            }}
          />
        ) : null}
        {showCreditInvite ? (
          <CreditInvite
            buyerId={targetBuyerId}
            onClose={() => {
              dispatch(da.buyers.all.getBuyers());
              handleOnClose({ buyerCreated: false });
            }}
          />
        ) : null}
      </FadeInStack>
    </AuthGuard>
  );
}
