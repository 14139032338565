import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { BankingAccountDbType } from "@pd/redux/types/dbTypes";

export type LinkTokenResType = { data: string } | ErrorResType;
export const fetchLinkToken = async (): Promise<LinkTokenResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/link`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export const fetchCreditApplicationLinkToken = async (
  jwt: string,
): Promise<LinkTokenResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/portal/credit/application/banking/link?auth=${jwt}`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type LinkBankAccountsResType = { data: null } | ErrorResType;
export const fetchLinkBankAccounts = async (
  publicToken: string,
  accountIds: string[],
): Promise<LinkBankAccountsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/link`,
    {
      method: "POST",
      body: JSON.stringify({
        public_token: publicToken,
        ids: accountIds,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response;
};

export type BankAccountsResType =
  | { data: BankingAccountDbType[] }
  | ErrorResType;
export const fetchBankAccounts = async (): Promise<BankAccountsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/banking/accounts`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};
