/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "@reduxjs/toolkit";
import { CreditApplicationStateType } from "@pd/layouts/BuyerCredit/types";
import { isBuyerCredit } from "@pd/layouts/BuyerCredit/redux/selectors";
import { createAsyncSlice } from "@pd/redux/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

export const selectCreditApplication = createSelector(
  (state: any) => state,
  (state: any): CreditApplicationStateType => {
    if (isBuyerCredit(state)) {
      return state.buyerCredit.creditApplication;
    }
    return createAsyncSlice({
      businessName: "",
      ein: "",
      website: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      },
      status: "",
      isBankLinked: false,
    });
  },
);

export const selectApiError = createSelector(
  (state: any) => state,
  (state: any): ErrorPayload => {
    let result = { message: "", status: 0 };
    if (isBuyerCredit(state)) {
      const r = state.buyerCredit.creditApplication.error;
      if (r) {
        result = r;
      }
    }
    return result;
  },
);

export const selectApiFetching = createSelector(
  (state: any) => state,
  (state: any): boolean => {
    let result = false;
    if (isBuyerCredit(state)) {
      const r = state.buyerCredit.creditApplication.fetching;
      if (r) {
        result = r;
      }
    }
    return result;
  },
);
export const selectApiSuccess = createSelector(
  (state: any) => state,
  (state: any): boolean => {
    let result = false;
    if (isBuyerCredit(state)) {
      const r = state.buyerCredit.creditApplication.success;
      if (r) {
        result = r;
      }
    }
    return result;
  },
);
