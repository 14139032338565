import { all, put, call, takeLeading, select } from "redux-saga/effects";
import {
  fetchLinkToken,
  LinkTokenResType,
  fetchCreditApplicationLinkToken,
  fetchLinkBankAccounts,
  LinkBankAccountsResType,
} from "@pd/api/banking";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { isBuyerCredit } from "@pd/utils/appCheck";
import { selectJwt } from "@pd/redux/selectors/auth";
import pla from "../actions";
import { rehydrateBankAccounts } from "./accounts";

export default function* plaidTokenSaga() {
  yield takeLeading(pla.tokens.getLinkToken, onGetLinkToken);
  yield takeLeading(pla.tokens.linkBankAccounts, onLinkBankAccounts);
}

function* onGetLinkToken() {
  try {
    yield all([
      put(pla.tokens.apiFetching(true)),
      put(pla.tokens.apiSuccess(false)),
      put(pla.tokens.apiError({ message: "", status: 0 })),
    ]);

    const jwt: string = yield select(selectJwt);
    const res: LinkTokenResType = isBuyerCredit()
      ? yield call(fetchCreditApplicationLinkToken, jwt)
      : yield call(fetchLinkToken);
    if ("error" in res) {
      yield all([
        put(pla.tokens.apiSuccess(false)),
        put(pla.tokens.apiFetching(false)),
        put(pla.tokens.apiError(res.error)),
      ]);
    } else {
      yield all([
        put(pla.tokens.apiFetching(false)),
        put(pla.tokens.apiSuccess(true)),
        put(pla.tokens.setLinkToken(res.data || "")),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occured while attempting to connect to Plaid. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        pla.tokens.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(pla.tokens.apiSuccess(false)),
      put(pla.tokens.apiFetching(false)),
    ]);
  }
}

function* onLinkBankAccounts(
  action: ReturnType<typeof pla.tokens.linkBankAccounts>,
) {
  try {
    yield all([
      put(pla.tokens.apiSuccess(false)),
      put(pla.tokens.apiFetching(true)),
      put(pla.tokens.apiError({ message: "", status: 0 })),
    ]);
    const res: LinkBankAccountsResType = yield call(
      fetchLinkBankAccounts,
      action.payload.publicToken,
      action.payload.accountIds,
    );
    if ("error" in res) {
      yield all([
        put(pla.tokens.apiSuccess(false)),
        put(pla.tokens.apiFetching(false)),
        put(pla.tokens.apiError(res.error)),
      ]);
    } else {
      yield all([
        call(rehydrateBankAccounts),
        put(pla.tokens.clearTokens()),
        put(pla.tokens.apiFetching(false)),
        put(pla.tokens.apiSuccess(true)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while trying to connect your bank account with Plaid. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        pla.tokens.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(pla.tokens.apiSuccess(false)),
      put(pla.tokens.apiFetching(false)),
    ]);
  }
}
