import { createReducer } from "@reduxjs/toolkit";
import { createAsyncSlice } from "@pd/redux/types";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  CreditAppRequestorFormType,
  CreditAppStateType,
} from "@pd/layouts/MktplaceDashboard/types/credit";
import { CreditAppStatusType } from "@pd/redux/types/dbTypes";

export const initCreditState = createAsyncSlice({
  api: null,
  ui: null,
});

const uiCreditAppTemplate = {
  id: "",
  companyName: "",
  taxId: "",
  website: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  },
  status: CreditAppStatusType.undefined,
};

export default function createCreditApplxnReducer() {
  return createReducer<CreditAppStateType>(initCreditState, (builder) => {
    builder
      .addCase(da.credit.app.apiFetching, onApiFetching)
      .addCase(da.credit.app.apiError, onApiError)
      .addCase(da.credit.app.apiSuccess, onApiSuccess)
      .addCase(da.credit.app.setUiData, onSetUiData)
      .addCase(da.credit.app.setApiData, onSetApiData)
      .addCase(da.credit.app.clearState, onClearState)
      .addCase(da.credit.app.hydrateUiApp, onHydrateUiApp);
  });
}

export function onApiFetching(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetApiData(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.setApiData>,
) {
  state.data.api = action.payload.data;
  if (action.payload.data) {
    state.data.ui = {
      id: action.payload.data.id,
      companyName: action.payload.data.companyName,
      taxId: action.payload.data.taxId,
      website: action.payload.data.website,
      status: action.payload.data.status,
      address: {
        line1: action.payload.data.address.line1,
        line2: action.payload.data.address.line2,
        city: action.payload.data.address.city,
        state: action.payload.data.address.state,
        postalCode: action.payload.data.address.postalCode,
        country: action.payload.data.address.country,
      },
    };
  }
}

export function onSetUiData(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.setUiData>,
) {
  if (!state.data.ui) {
    state.data.ui = {
      ...uiCreditAppTemplate,
      [action.payload.key]: action.payload.value,
    };
  } else if (
    !state.data.ui.status ||
    state.data.ui?.status === CreditAppStatusType.undefined
  ) {
    if (action.payload.key in state.data.ui) {
      state.data.ui = {
        ...state.data.ui,
        [action.payload.key]: action.payload.value,
      };
    }
  }
}

export function onClearState(state: CreditAppStateType) {
  state.data.ui = null;
  state.data.api = null;
}

export function onHydrateUiApp(
  state: CreditAppStateType,
  action: ReturnType<typeof da.credit.app.hydrateUiApp>,
) {
  const data = action.payload.dbBuyer;
  let next: CreditAppRequestorFormType | null = state.data.ui;
  if (!next) {
    next = { ...uiCreditAppTemplate };
  }
  next.id = data.id;
  next.companyName = data.companyName;
  next.taxId = data.taxId;
  next.website = data.website;
  next.status = data.creditInfo.creditAppStatus;
  next.address = {
    line1: data.address.line1,
    line2: data.address.line2,
    city: data.address.city,
    state: data.address.state,
    postalCode: data.address.postalCode,
    country: data.address.country,
  };
  state.data.ui = next;
}
