import { createAction } from "@reduxjs/toolkit";
import { PlaidLinkError, PlaidLinkOnExitMetadata } from "react-plaid-link";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { BankingAccountDbType } from "@pd/redux/types/dbTypes";

const plaidAccountsActions = {
  apiFetching: createAction(
    "plaidLink/accounts/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiSuccess: createAction(
    "plaidLink/accounts/apiSuccess",
    (success: boolean) => ({ payload: { success } }),
  ),
  apiError: createAction(
    "plaidLink/accounts/apiError",
    (error: ErrorPayload) => ({ payload: { error } }),
  ),
  setAcctUpdateOnError: createAction(
    "plaidLink/accounts/setAcctUpdateOnError",
    (error: PlaidLinkError, metaData: PlaidLinkOnExitMetadata) => ({
      payload: { error, metaData },
    }),
  ),
  resetAcctError: createAction(
    "plaidLink/accounts/resetAcctError",
    (accountId: string) => ({
      payload: { accountId },
    }),
  ),
  updateLinkedAcct: createAction(
    "plaidLink/accounts/updateLinkedAcct",
    (accountId: string) => ({
      payload: { accountId },
    }),
  ),
  clearAccounts: createAction("plaidLink/accounts/clearAccounts"),
  getBankAccounts: createAction("plaidLink/accounts/getBankAccounts"),
  setBankAccounts: createAction(
    "plaidLink/accounts/setBankAccounts",
    (accounts: BankingAccountDbType[]) => ({
      payload: { accounts },
    }),
  ),
  setLinkAccountId: createAction(
    "plaidLink/accounts/setLinkAccountId",
    (accountId: string) => ({
      payload: { accountId },
    }),
  ),
};
export default plaidAccountsActions;
