import tokenActions from "./tokens";
import accountsActions from "./accounts";
import eventsActions from "./events";

const actions = {
  tokens: tokenActions,
  accounts: accountsActions,
  events: eventsActions,
};
export default actions;
