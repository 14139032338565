import { createAction } from "@reduxjs/toolkit";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { AddressDbType, BuyerDetailDbType } from "@pd/redux/types/dbTypes";
import { CreateBuyerFormTypes } from "@pd/layouts/MktplaceDashboard/types/crudBuyer";

const crudBuyerActions = {
  apiFetching: createAction(
    "dashboard/crudBuyer/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/crudBuyer/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/crudBuyer/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  setBuyerApiData: createAction(
    "dashboard/crudBuyer/setBuyerApiData",
    (buyer: BuyerDetailDbType | null) => ({
      payload: { buyer },
    }),
  ),
  setBuyerUiData: createAction(
    "dashboard/crudBuyer/setBuyerUiData",
    (key: string, value: string | AddressDbType) => ({
      payload: { key, value },
    }),
  ),
  clearUpsertBuyer: createAction("dashboard/crudBuyer/clearUpsertBuyer"),
  createBuyer: createAction(
    "dashboard/crudBuyer/createBuyer",
    (buyer: CreateBuyerFormTypes, confirm: boolean) => ({
      payload: { buyer, confirm },
    }),
  ),
  editBuyer: createAction(
    "dashboard/crudBuyer/editBuyer",
    (buyer: CreateBuyerFormTypes, id: string, confirm: boolean) => ({
      payload: { buyer, id, confirm },
    }),
  ),
  getBuyerById: createAction(
    "dashboard/crudBuyer/getBuyerById",
    (id: string) => ({
      payload: { id },
    }),
  ),
  setBuyerById: createAction(
    "dashboard/crudBuyer/setBuyerById",
    (buyer: BuyerDetailDbType) => ({
      payload: { buyer },
    }),
  ),
};

export default crudBuyerActions;
