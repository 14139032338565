import { useCallback, useState, useMemo, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Stack,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import { SortDirType, stableSort, getComparator } from "@pd/utils/stableSort";
import formatIsoTimeWithTz from "@pd/utils/formatIsoTimeWithTz";
import formatCurrency from "@pd/utils/formatCurrency";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import {
  selectSelectedOrders,
  selectAllOrders,
  selectAllOrdersFetching,
  selectAllOrdersError,
  selectAllOrdersFiltered,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/orders/allOrders";
import StitchLoading from "@pd/components/StitchLoading";
import { OrderDbType } from "@pd/redux/types/dbTypes";
import DynamicTableHOC from "@pd/components/DynamicTable";
import { FadeInBox } from "@pd/components/FadeComponents";
import CopyIdButton from "@pd/components/CopyIdButton";
import {
  selectSortDir,
  selectSortKey,
  selectSortEnabled,
  selectTableTotalRows,
  selectTableOffset,
  selectTableLimit,
  selectTableFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/table";
import { TablePaginationType } from "@pd/layouts/MktplaceDashboard/types/tables";
import { selectProfile } from "@pd/redux/selectors/auth";
import StatusCell from "@pd/components/DynamicTable/StatusCell";
import CsvUpload from "@pd/layouts/MktplaceDashboard/pages/Orders/components/modals/CsvUpload";
import { OrderFinanceStatus } from "@pd/redux/types/orders";

import zipSelectedOrders from "../../utils/zipSelectedOrders";
import { orderColumns } from "./tableSchemas";
import { OrderDataType } from "../../types";
import ConfirmDelete from "../modals/ConfirmDelete";
import OrderHeaderActions from "./OrderHeaderActions";
import OrderActions from "./OrderActions";
import { TableSelectionProvider } from "../../providers/TableSelectionContext";

type Props = {
  onShowCreateOrder: (id: string) => void;
  onSetOrderId: (id: string) => void;
};
export default function OrdersTable(props: Props) {
  const dispatch = useAppDispatch();

  const apiTableFetching = useAppSelector(selectTableFetching);
  const apiOrdersFetching = useAppSelector(selectAllOrdersFetching);
  const apiOrdersError = useAppSelector(selectAllOrdersError);
  const allOrders = useAppSelector(selectAllOrders);
  const filteredOrders = useAppSelector(selectAllOrdersFiltered);
  const selectedOrders = useAppSelector(selectSelectedOrders);
  const totalRows = useAppSelector(selectTableTotalRows);
  const offset = useAppSelector(selectTableOffset);
  const limit = useAppSelector(selectTableLimit);
  const sortDir = useAppSelector(selectSortDir);
  const sortKey = useAppSelector(selectSortKey);
  const sortEnabled = useAppSelector(selectSortEnabled);
  const profile = useAppSelector(selectProfile);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uiFetching, setUiFetching] = useState(false);
  const [deleteFetching, setDeleteFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [targetDeleteOrder, setTargetDeleteOrder] =
    useState<OrderDbType | null>(null);

  useEffect(() => {
    if (!apiOrdersFetching && uiFetching) {
      setUiFetching(false);
    } else if (!apiOrdersFetching && deleteFetching) {
      setDeleteFetching(false);
      setTimeout(() => setShowDeleteModal(false), 2000);
    } else if (apiOrdersError.status && uiFetching) {
      setUiFetching(false);
    } else if (apiOrdersError.status && deleteFetching) {
      setDeleteFetching(false);
    }
  }, [apiOrdersError, apiOrdersFetching, uiFetching, deleteFetching]);

  useEffect(() => {
    dispatch(da.table.getTableData({ dataSrc: "orders" }));
    return () => {
      dispatch(da.table.clearTableFilters());
    };
  }, []);

  useEffect(() => {
    const initialSortFilter: TablePaginationType = {
      total: totalRows,
      offset,
      limit,
      sortDir: sortDir === "asc" ? "asc" : "desc",
      sortKey,
      sortEnabled: false,
    };
    dispatch(
      da.table.getTableDataOnFilterChange({
        changeSrc: "filters",
        dataSrc: "orders",
        filter: initialSortFilter,
      }),
    );
  }, [totalRows]);

  const handleRequestSort = useCallback(
    (_: any, newSortKey: string) => {
      if (!sortEnabled) {
        return;
      }
      const sortFilter: TablePaginationType = {
        total: totalRows,
        offset,
        limit,
        sortDir: sortDir === "asc" ? "desc" : "asc",
        sortKey: newSortKey,
        sortEnabled: false,
      };
      if (newSortKey !== sortKey) {
        sortFilter.sortDir = sortDir;
      }
      dispatch(
        da.table.getTableDataOnFilterChange({
          changeSrc: "filters",
          dataSrc: "orders",
          filter: sortFilter,
        }),
      );
    },
    [sortEnabled, totalRows, offset, limit, sortDir, sortKey],
  );

  const targetRows = filteredOrders;
  const rows = zipSelectedOrders(targetRows, selectedOrders);
  const sortedRows = useMemo(
    () =>
      stableSort(
        rows,
        getComparator(sortDir as SortDirType, sortKey as keyof OrderDataType),
      ),
    [rows, sortDir, sortKey],
  );

  const handleShowDeleteModal = (order: OrderDbType) => {
    setTargetDeleteOrder(order);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    dispatch(da.orders.crud.apiSuccess(false));
    setTargetDeleteOrder(null);
    setShowDeleteModal(false);
  };

  const handleCloseUploadModal = () => {
    setShowModal(false);
  };

  const handleOnVoid = (id: string) => {
    dispatch(da.orders.crud.voidOrder(id));
  };

  const handleOnSelection = (id: string) => {
    dispatch(da.orders.all.setSelectedOrder(id));
  };

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    row: OrderDbType,
  ) => {
    const dataAction = (event.target as HTMLElement).getAttribute(
      "data-action",
    );
    if (
      [
        dataAction,
        dataAction?.includes("delete"),
        dataAction?.includes(row.id),
      ].every(Boolean)
    ) {
      handleShowDeleteModal(row);
    } else if (
      [
        dataAction,
        dataAction?.includes("void"),
        dataAction?.includes(row.id),
      ].every(Boolean)
    ) {
      handleOnVoid(row.id);
    } else if (
      [
        dataAction,
        dataAction?.includes("selection"),
        dataAction?.includes(row.id),
      ].every(Boolean)
    ) {
      handleOnSelection(row.id);
    } else if (row.status === "created" && row.id) {
      props.onShowCreateOrder(row.id);
    } else {
      props.onSetOrderId(row.id);
    }
  };

  const renderTable = () => {
    if (!uiFetching && !allOrders.length) {
      return (
        <FadeInBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
            borderColor: "grey.300",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">No orders</Typography>
            <Typography variant="subtitle1">
              Looks like you {"don't"} have any orders. Add one to get started.
            </Typography>
            <Button
              color="primary"
              onClick={() => props.onShowCreateOrder("")}
              variant="outlined"
            >
              Add order
            </Button>
          </Stack>
        </FadeInBox>
      );
    }
    if (apiTableFetching && !allOrders.length) {
      return (
        <FadeInBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "400px",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <StitchLoading size="large" />
        </FadeInBox>
      );
    }
    return (
      <TableSelectionProvider>
        <DynamicTableHOC
          columnDefs={orderColumns}
          onHeaderSort={handleRequestSort}
          order={sortDir}
          orderBy={sortKey as keyof OrderDataType}
          tableSrc="orders"
          showLoading={apiTableFetching}
          autoHidePagination={!sortedRows.length}
        >
          {sortedRows.map((row) => (
            <TableRow
              onClick={(event) => handleRowClick(event, row)}
              key={row.id}
              sx={{
                cursor: "pointer",
                td: {
                  fontFamily: "CircularRegular",
                  fontSize: "14px",
                  maxWidth: "140px",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <TableCell
                sx={{
                  position: "relative",
                }}
              >
                <Checkbox
                  checked={row.selected}
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "-12px",
                    width: "100%",
                    height: "100%",
                  }}
                  inputProps={{
                    "aria-label": "select order",
                    "data-action": `selection-${row.id}`,
                  }}
                />
              </TableCell>
              <TableCell>
                <OrderActions
                  voidable={!row.payoutId}
                  role={profile?.role}
                  showLoading={row.id === targetDeleteOrder?.id}
                  orderData={row}
                  onDelete={handleShowDeleteModal}
                  onVoid={handleOnVoid}
                />
              </TableCell>
              <TableCell>
                <StatusCell status={row.status} type="order-status" />
              </TableCell>
              <TableCell>
                <StatusCell
                  status={
                    row.isFinanced
                      ? OrderFinanceStatus.yes
                      : OrderFinanceStatus.no
                  }
                  type="order-finance-status"
                />
              </TableCell>
              <TableCell>{formatIsoTimeWithTz(row.createdAt)}</TableCell>
              <TableCell>
                <CopyIdButton id={row.id} />
              </TableCell>
              <TableCell>
                <CopyIdButton id={row.referenceId} />
              </TableCell>
              <TableCell>{row.buyerName}</TableCell>
              <TableCell>{row.vendorName}</TableCell>
              <TableCell>{formatCurrency(row.total)}</TableCell>
            </TableRow>
          ))}
        </DynamicTableHOC>
      </TableSelectionProvider>
    );
  };

  return (
    <Stack sx={{ width: "100%", flexGrow: 1 }}>
      <OrderHeaderActions
        onShowCreateOrder={props.onShowCreateOrder}
        onShowCsvUpload={() => setShowModal((prev) => !prev)}
      />
      {renderTable()}
      <ConfirmDelete
        showModal={showDeleteModal}
        orderId={targetDeleteOrder?.id || ""}
        referenceId={targetDeleteOrder?.referenceId || ""}
        onClose={handleCloseDeleteModal}
      />
      <CsvUpload showModal={showModal} onClose={handleCloseUploadModal} />
    </Stack>
  );
}
