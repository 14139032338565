import { Box, Stack, Typography, Button, useTheme } from "@mui/material";
import CopyIdButton from "@pd/components/CopyIdButton";
import StitchSuccessIcon from "@pd/components/StitchIcons/StitchSuccessIcon";

type Props = {
  link: string;
  onClose: () => void;
};
export default function InviteSentDilaogue(props: Props) {
  const theme = useTheme();
  return (
    <Stack gap={6} alignItems="center" sx={{ p: 4 }}>
      <Stack gap={1} alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Email with login link sent!</Typography>
        <StitchSuccessIcon
          style={{ fontSize: "48px", height: "100px" }}
          size="medium"
        />
      </Stack>
      <Box
        sx={{
          display: props.link ? "block" : "none",
          backgroundColor: theme.palette.secondary.lighter,
          border: `1px solid ${theme.palette.secondary.light}`,
          borderRadius: "4px",
          maxWidth: "50%",
          p: 2,
        }}
      >
        <CopyIdButton
          id={props.link}
          idStyle={{
            width: "100%",
            fontSize: "18px",
          }}
        />
      </Box>
      <Typography align="center">
        {"Here's"} a copy of their invitation link. It expires in 48 hours.
      </Typography>
      <Button
        variant="contained"
        onClick={props.onClose}
        sx={{ width: "200px", height: "40px" }}
      >
        Ok
      </Button>
    </Stack>
  );
}
