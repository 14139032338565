import {
  all,
  call,
  put,
  delay,
  takeLeading,
  takeLatest,
  select,
} from "redux-saga/effects";
import {
  fetchDashboardBuyers,
  fetchDashboardBuyersSearch,
  DashboardBuyersSearchResType,
} from "@pd/api/dashboard/buyers";
import type { DashboardBuyersResType } from "@pd/api/dashboard/buyers";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { TableAllFiltersType } from "@pd/layouts/MktplaceDashboard/types/tables";
import tracer from "@pd/tracing";
import da from "../../actions";
import { selectTableFilters } from "../../selectors/table";

export default function* allBuyerSagas() {
  yield all([
    takeLeading(da.buyers.all.getBuyers.toString(), handleGetAllBuyers),
    takeLatest(da.buyers.all.searchBuyers.toString(), onSearchBuyers),
  ]);
}

export function* handleGetAllBuyers(): Generator<any, string, any> {
  try {
    yield all([
      put(da.buyers.all.apiSuccess(true)),
      put(da.buyers.all.apiFetching(true)),
      put(da.buyers.all.apiError({ message: "", status: 0 })),
    ]);
    const filters = yield select(selectTableFilters);
    const res: DashboardBuyersResType = yield call(
      fetchDashboardBuyers,
      filters,
    );
    if ("error" in res) {
      tracer.error(
        "Error fetching dashboard buyers",
        tracer.ids.domain.SAGAS.buyers,
        { error: res.error.message },
      );
      yield all([
        put(da.buyers.all.apiFetching(false)),
        put(da.buyers.all.apiSuccess(false)),
        put(da.buyers.all.apiError(res.error)),
      ]);
    } else {
      if (res.data.length) {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: res.data[0].totalResults,
          }),
        );
      } else {
        yield put(
          da.table.setTablePagination({
            ...filters.pagination,
            total: 0,
          }),
        );
      }
      if (res.data.length) {
        yield put(da.buyers.all.setBuyers(res.data));
      }
      yield all([
        put(da.buyers.all.apiSuccess(true)),
        put(da.buyers.all.apiFetching(false)),
      ]);
    }
    return "";
  } catch (error) {
    const errMsg =
      "An error occured while loading your buyers. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.buyers.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.buyers.all.apiFetching(false)),
      put(da.buyers.all.apiSuccess(false)),
    ]);
    return errMsg;
  }
}

/**
 * This function is used to get buyers by filter. It's called from the Table
 * Saga (actions.table.getTableData) as a helper function. The Table Saga is responsible for setting the
 * table filters and handling async state (fetching, error, etc.). This function
 * is only responsible for fetching the orders by filter and returning the
 * response.
 * @returns {OrdersResType} - The response from the API
 */
export function* handleApplyFiltersBuyersTable(
  filters: TableAllFiltersType,
): Generator<any, null | string, any> {
  try {
    const res: DashboardBuyersResType = yield call(
      fetchDashboardBuyers,
      filters,
    );
    if ("error" in res) {
      tracer.warn(
        "Error fetching dashboard buyers via table filters",
        tracer.ids.domain.SAGAS.buyers,
        { error: res.error.message },
      );
      return res.error.message;
    }
    if (res.data.length) {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: res.data[0].totalResults,
        }),
      );
    } else {
      yield put(
        da.table.setTablePagination({
          ...filters.pagination,
          total: 0,
        }),
      );
    }
    yield put(da.buyers.all.setFilteredBuyers(res.data));
    return null;
  } catch (error: unknown) {
    const errMsg =
      "Oops!, an error occurred while fetching the buyers table. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    return errMsg;
  }
}

function* onSearchBuyers(
  action: ReturnType<typeof da.buyers.all.searchBuyers>,
) {
  yield delay(400);
  try {
    yield all([
      put(da.buyers.all.apiSuccess(false)),
      put(da.buyers.all.apiFetching(true)),
      put(da.buyers.all.apiError({ message: "", status: 0 })),
    ]);
    const res: DashboardBuyersSearchResType = yield call(
      fetchDashboardBuyersSearch,
      action.payload.query,
    );
    if ("error" in res) {
      tracer.warn(
        "Error fetching dashboard buyers via search",
        tracer.ids.domain.SAGAS.buyers,
        { error: res.error.message },
      );
      yield all([
        put(da.buyers.all.apiFetching(false)),
        put(da.buyers.all.apiError(res.error)),
        put(da.buyers.all.apiSuccess(false)),
      ]);
    } else {
      yield all([
        put(da.buyers.all.setSearchedBuyers(res?.data || [])),
        put(da.buyers.all.apiFetching(false)),
        put(da.buyers.all.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while searching buyers. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.buyers.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.buyers.all.apiFetching(false)),
      put(da.buyers.all.apiSuccess(false)),
    ]);
  }
}
