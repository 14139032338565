import { Box } from "@mui/material";
import triangleAlertSvg from "@pd/assets/svgs/stitch-alert-triangle.svg";

type Props = {
  style?: object;
  size?: "small" | "medium" | "large";
};
export default function StitchTriangleAlertIcon({
  style = {},
  size = "medium",
}: Props) {
  const small = (
    <Box sx={{ ...style }}>
      <img src={triangleAlertSvg} alt="Alert" style={{ width: "50px" }} />
    </Box>
  );
  if (size === "small") {
    return small;
  }

  const medium = (
    <Box sx={{ ...style }}>
      <img src={triangleAlertSvg} alt="Alert" style={{ width: "120px" }} />
    </Box>
  );
  if (size === "medium") {
    return medium;
  }

  return (
    <Box sx={{ ...style }}>
      <img src={triangleAlertSvg} alt="Alert" />
    </Box>
  );
}
