import { BuyerTableDbType } from "@pd/redux/types/dbTypes";
import { BuyersTableDataType } from "@pd/layouts/MktplaceDashboard/pages/Buyers/types";

export default function transformBuyerTableData(
  buyers: BuyerTableDbType[],
): BuyersTableDataType[] {
  const results = buyers.map((t) => ({
    id: t.id.toString(),
    name: t.name,
    phone: t.phone.toString(),
    email: t.email,
    extId: t.extId,
    creditAppStatus: t.applicationStatus,
    creditEvalStatus: t.creditStatus,
    maxAllowedCredit: t.maxAllowedCredit,
    consumedCredit: t.consumedCredit,
  }));
  return results;
}
