import { Button, Stack, useTheme } from "@mui/material";
import StitchLoading from "@pd/components/StitchLoading";
import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";

type Props = {
  buttonText: string;
  buttonWidth?: string;
  endAdornment?: React.JSX.Element;
  variant: "contained" | "outlined" | "text";
  color: "primary" | "secondary" | "inherit" | "error";
  logoColor: "white" | "black";
  loadingSize: "small" | "medium" | "large";
  loading: boolean;
  disabled?: boolean;
  loadingPosition: { top: number; left: number };
  success: boolean;
  onClick: () => void;
  style?: { [key: string]: string };
};
export default function StitchAsyncButton(props: Props) {
  const { buttonWidth = "", disabled = false, style: _style = {} } = props;
  const theme = useTheme();
  let style: { [key: string]: string } = {
    height: "40px",
    ..._style,
  };
  if (buttonWidth) {
    style = {
      ...style,
      width: buttonWidth,
    };
  }

  const getBackgroundColor = () => {
    if (props.variant === "contained") return theme.palette.primary.light;
    if (props.variant === "outlined") return theme.palette.secondary.light;
    return "transparent";
  };

  const getButtonText = () => {
    if (props.loading)
      return (
        <StitchLoading
          color={props.logoColor}
          size={props.loadingSize}
          position={props.loadingPosition}
        />
      );
    if (props.success) {
      return <CheckCircleOutlined style={{ fontSize: "24px" }} />;
    }
    if (!props.endAdornment) return props.buttonText;
    return (
      <Stack
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        {props.buttonText}
        {props.endAdornment}
      </Stack>
    );
  };

  return (
    <Button
      disabled={disabled}
      fullWidth={!buttonWidth}
      variant={props.variant}
      color={props.color}
      onClick={() => {
        if (!props.loading) props.onClick();
      }}
      style={style}
      sx={{
        "&:hover": {
          backgroundColor: getBackgroundColor(),
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      {getButtonText()}
    </Button>
  );
}
