import { useState, useEffect, useCallback, useRef } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Fade, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import authActions from "@pd/redux/actions/auth";
import StitchLoading from "@pd/components/StitchLoading";
import { getCookieName } from "@pd/utils/appCheck";
import {
  selectAuthApiError,
  selectAuthApiFetching,
  selectShowRefreshSession,
} from "@pd/redux/selectors/auth";
import ModalTitle from "@pd/components/ModalTitle";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

import { modalStyles } from "../constants/styles";

export default function RefreshSession() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiError = useAppSelector(selectAuthApiError);
  const apiFetching = useAppSelector(selectAuthApiFetching);
  const showRefreshSession = useAppSelector(selectShowRefreshSession);
  const [countdown, setCountdown] = useState<number>(60);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const targetTimeRef = useRef<number | null>(Date.now() + 60 * 1000);

  const handleRefresh = () => {
    dispatch(authActions.refreshSession());
  };

  const handleCloseOnErrorOrTimeout = () => {
    dispatch(authActions.setShowRefreshMsg(false));
    dispatch(authActions.logout());
    try {
      Cookies.remove(getCookieName());
    } catch (err) {
      console.error(err);
    }
    navigate("/auth/login/email", { replace: true });
  };

  useEffect(() => {
    if (countdown <= 0) {
      handleCloseOnErrorOrTimeout();
    }
  }, [countdown]);

  useEffect(() => {
    const updateCountdown = () => {
      const remainingTime = Math.ceil(
        (targetTimeRef.current! - Date.now()) / 1000,
      );
      if (remainingTime <= 0) {
        setCountdown(0);
      } else {
        setCountdown(remainingTime);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateCountdown();
      } else if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    intervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (!showRefreshSession) return null;

  const renderContent = useCallback((error: ErrorPayload, count: number) => {
    if (error.status) {
      setTimeout(() => {
        handleCloseOnErrorOrTimeout();
      }, 5000);
      return (
        <>
          <Typography variant="h3">Sorry there was a problem</Typography>
          <Typography variant="body1">
            {error.message ||
              "There was an error refreshing your session. Please try again."}
          </Typography>
          <Button variant="contained" onClick={handleCloseOnErrorOrTimeout}>
            Close
          </Button>
        </>
      );
    }
    if (apiFetching) {
      return <StitchLoading />;
    }
    return (
      <>
        <Typography variant="h3">Are you still there?</Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Please click the button below to refresh your session.
          <br />
          Your session is about to expire.
        </Typography>
        <Typography variant="h1">{count}</Typography>
        <Button variant="contained" onClick={handleRefresh}>
          Refresh
        </Button>
      </>
    );
  }, []);

  return (
    <Modal open disableEnforceFocus closeAfterTransition>
      <Fade in>
        <Stack gap={2} sx={modalStyles} justifyContent="flex-start">
          <ModalTitle
            title={(() => {
              if (apiFetching) return "Refreshing session...";
              if (apiError.status) return "Error";
              return "Session Expiring";
            })()}
          />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{ my: "auto" }}
          >
            {renderContent(apiError, countdown)}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
