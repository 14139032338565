import { useEffect, useCallback, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  useTheme,
  Divider,
} from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  selectApiBuyer,
  selectCrudBuyerError,
  selectCrudBuyerFetching,
  selectCrudBuyerSuccess,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/buyers/crudBuyer";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import StitchLoading from "@pd/components/StitchLoading";
import formatCurrency from "@pd/utils/formatCurrency";
import StitchErrorIcon from "@pd/components/StitchIcons/StitchErrorIcon";
import StitchTriangleAlertIcon from "@pd/components/StitchIcons/StitchTriangleAlertIcon";
import formatNamePosessive from "@pd/utils/formatNamePosessive";
import { CreditAppStatusType } from "@pd/redux/types/dbTypes";

type Props = {
  buyerId: string;
  onClose: () => void;
  onOpenInvite: () => void;
};

function StitchDivider() {
  return (
    <Divider
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      }}
    />
  );
}

export default function BuyerDetails(props: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const apiFetching = useAppSelector(selectCrudBuyerFetching);
  const apiError = useAppSelector(selectCrudBuyerError);
  const apiSuccess = useAppSelector(selectCrudBuyerSuccess);
  const apiBuyer = useAppSelector(selectApiBuyer);
  const [showInviteBtn, setShowInviteBtn] = useState(false);

  const clearState = useCallback(() => {
    dispatch(da.buyers.crud.clearUpsertBuyer());
    dispatch(da.buyers.crud.apiSuccess(false));
    dispatch(da.buyers.crud.apiFetching(false));
    dispatch(
      da.buyers.crud.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  useEffect(() => {
    clearState();
    if (props.buyerId) {
      dispatch(da.buyers.crud.getBuyerById(props.buyerId));
    }
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    const appStatus = apiBuyer?.creditInfo?.creditAppStatus || "";
    if (appStatus !== CreditAppStatusType.pending) {
      setShowInviteBtn(true);
    } else {
      setShowInviteBtn(false);
    }
  }, [apiBuyer, props.buyerId]);

  const renderContent = () => {
    if (!apiFetching && apiError.status) {
      return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            flexGrow: 1,
          }}
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <StitchErrorIcon />
          <Typography variant="h3">Oops...Something went wrong</Typography>
          <Typography variant="h5">Please refresh and try again.</Typography>
          <Typography>{apiError.message}</Typography>
        </Stack>
      );
    }

    if (apiFetching) {
      return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            flexGrow: 1,
          }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading />
        </Stack>
      );
    }

    if (!apiBuyer && apiSuccess) {
      return (
        <Stack
          gap={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            flexGrow: 1,
          }}
        >
          <StitchTriangleAlertIcon />
          <Typography variant="h4">Oops...</Typography>
          <Typography variant="h5" align="center">
            Looks like we {"can't"} find the buyer for this form. <br /> Please
            refresh and try again.
          </Typography>
        </Stack>
      );
    }

    return (
      <FadeInStack
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "500px",
          flexGrow: 1,
        }}
      >
        <Stack
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{ width: "100%", height: "100%" }}
          gap={2}
        >
          <Stack gap={1} sx={{ mb: 3 }}>
            <Typography variant="h4">{apiBuyer?.companyName}</Typography>
            <Typography variant="h6" color="secondary">
              Business record
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">EIN</Typography>
            <Typography>{apiBuyer?.taxId}</Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Reference ID</Typography>
            <Typography>{apiBuyer?.extId}</Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Credit usage</Typography>
            <Typography>
              {`${formatCurrency(
                apiBuyer?.creditInfo.consumedCredit || 0,
              )} / ${formatCurrency(
                apiBuyer?.creditInfo.maxAllowedCredit || 0,
              )}`}
            </Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Website</Typography>
            <Typography>{apiBuyer?.website}</Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Email</Typography>
            <Typography>{apiBuyer?.email}</Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Phone</Typography>
            <Typography>{apiBuyer?.phone}</Typography>
          </Stack>
          <StitchDivider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6">Address</Typography>
            <Stack alignItems="flex-end">
              <Typography>{apiBuyer?.address?.line1 || ""}</Typography>
              {apiBuyer?.address.line2 ? (
                <Typography>{apiBuyer?.address?.line2}</Typography>
              ) : null}
              <Typography>
                <span>
                  {apiBuyer?.address?.city
                    ? `${apiBuyer?.address?.city}, `
                    : ""}
                </span>
                &nbsp;
                <span>{apiBuyer?.address?.state || ""}</span>&nbsp;
                <span>{apiBuyer?.address?.postalCode}</span>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Button
            key={apiBuyer?.id}
            onClick={props.onOpenInvite}
            sx={{
              display: showInviteBtn ? "flex" : "none",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.secondary.dark,
              },
              width: "176px",
              height: "176px",
              px: 2,
              py: 2,
            }}
          >
            <Stack alignItems="flex-start">
              <Typography sx={{ pb: 2 }}>Credit review</Typography>
              <Typography align="left" lineHeight="20px">
                Verify {formatNamePosessive(apiBuyer?.companyName || "")}{" "}
                business standing to access AR financing
              </Typography>
              <Typography sx={{ width: "100%" }} align="right">
                <ArrowRightOutlined style={{ fontSize: "20px" }} />
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </FadeInStack>
    );
  };

  return (
    <Dialog
      open
      onClose={props.onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      aria-labelledby="create-edit-buyer-title"
      PaperProps={{
        sx: {
          height: "80%",
          width: "100%",
          maxWidth: "600px",
          maxHeight: "830px",
        },
      }}
    >
      <DialogContent sx={{ p: 4 }}>{renderContent()}</DialogContent>
    </Dialog>
  );
}
